<template>
  <v-app :style="colorVapp">
    <onebox_toolbar_inboxoutbox
      :parentfolder="parentfolder"
      @inputdata="filteredItems"
      @loadfile="loadfolder()"
      @callparentfolder="getSourceSystem()"
      @callstorage="loadstorage()"
    ></onebox_toolbar_inboxoutbox>
    <v-content>
      <!-- <v-divider></v-divider> -->

      <v-overlay :value="processloader" absolute :color="color.BG" dark>
        <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
        <br />
        <br />
        <span :style="colorProgress">&nbsp; &nbsp; loading</span>
      </v-overlay>
      <v-overlay :value="loaddataprogress_print" z-index="2" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay>

      <v-list nav :color="color.BG">
        <v-list-item class="text-left">
          <v-list-item-avatar class="text-center">
            <v-avatar :color="color.theme" size="35">
              <v-icon dark size="20">{{ defaulticon }}</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span :style="headerPage">{{ Labeldata }} ({{ $route.query.sourcesystem }})</span>
            </v-list-item-title>
          </v-list-item-content>
          <!-- dialog-inboxapprove -->
          <!-- <v-list-item-icon class="mr-8">
              <v-btn :color="color.theme" dark @click="openinbox = true">Approve File
                    <v-icon size="30" color="white">insert_drive_file</v-icon>
              </v-btn>
          </v-list-item-icon>-->
        </v-list-item>
      </v-list>
      <v-card class="elevation-0" :color="color.BG" id="thiscontainer_inboxsourcesystem">
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap justify-center>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <v-expansion-panels v-model="panel" multiple outlined>
                  <v-expansion-panel class="pa-1">
                    <v-expansion-panel-header>
                      <span :style="headerPage">{{ $t("dcmPage.conditionFile") }}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-1">
                      <v-form>
                        <v-layout row wrap fill-height justify-center>
                          <v-flex xs12 sm12 md5 lg5 class="pa-1">
                            <v-container fluid>
                              <v-layout row wrap justify-center>
                                <!-- ช่องกรอกชื่อไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="filename"
                                    :label="$t('myinboxPage.conditionOptional.filename')"
                                    prepend-icon="insert_drive_file"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                <!-- ช่องเลือกประเภทไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="fileextension"
                                    :items="listExtensionFile"
                                    item-value="data_type"
                                    item-text="data_type"
                                    :label="$t('myinboxPage.conditionOptional.fileextension')"
                                    prepend-icon="extension"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                    multiple
                                  >
                                    <template v-slot:prepend-item>
                                      <v-list-item ripple @click="fn_selectall_fileextension">
                                        <v-list-item-action>
                                          <v-icon :color="fileextension.length > 0 ? 'indigo darken-4' : ''">
                                            {{ icons }}
                                          </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            Select All
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                      <span v-if="index < maxDisplayfileextension">{{ item.data_type }}</span>
                                      <span v-if="index === maxDisplayfileextension" class="grey--text caption"
                                        >(+{{ fileextension.length - maxDisplayfileextension }} )</span
                                      >
                                    </template>
                                  </v-select>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogFromdateupload"
                                    v-model="modalFromdateupload"
                                    :return-value.sync="fromdateupload"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.fromdateupload')"
                                        v-model="fromdateupload"
                                        @click:prepend="modalFromdateupload = true"
                                        prepend-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        @click:clear="todateupload = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="fromdateupload"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="changeFromdateupload()"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn dark color="error" @click.stop="modalFromdateupload = false">{{
                                        $t("myinboxPage.conditionOptional.closefromdateupload")
                                      }}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogTodateupload"
                                    v-model="modalTodateupload"
                                    :return-value.sync="todateupload"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        clearable
                                        readonly
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.todateupload')"
                                        v-model="todateupload"
                                        prepend-icon="event"
                                        @click:prepend="modalTodateupload = true"
                                        v-on="on"
                                        outlined
                                        dense
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todateupload"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="$refs.dialogTodateupload.save(todateupload)"
                                      :min="fromdateupload"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn dark color="error" @click.stop="modalTodateupload = false">{{
                                        $t("myinboxPage.conditionOptional.closetodateupload")
                                      }}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sendername"
                                    :label="$t('myinboxPage.conditionOptional.sendername')"
                                    prepend-icon="account_box"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                <!-- ช่องกรอก ผู้รับ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="receivername"
                                    :label="$t('myinboxPage.conditionOptional.receivername')"
                                    prepend-icon="account_circle"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>

                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <!-- ช่องเลือก ประเภเอกสาร -->
                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documenttype"
                                    :items="listDoctype"
                                    item-value="document_type_code"
                                    :item-text="$t('default') === 'th' ? 'document_type_th' : 'document_type_eng'"
                                    :label="$t('myinboxPage.conditionOptional.doctype')"
                                    prepend-icon="line_style"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                    multiple
                                  >
                                    <template v-slot:prepend-item>
                                      <v-list-item ripple @click="fn_selectall">
                                        <v-list-item-action>
                                          <v-icon :color="documenttype.length > 0 ? 'indigo darken-4' : ''">
                                            {{ icon }}
                                          </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            Select All
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template v-slot:selection="{ item, index }">
                                      <span v-if="index < maxDisplay">{{ $t('default') === 'th' ? item.document_type_th : item.document_type_eng }} , </span>
                                      <span v-if="index === maxDisplay" class="grey--text caption"
                                        >(+{{ documenttype.length - maxDisplay }} ประเภท)</span
                                      >
                                    </template>
                                  </v-select>
                                </v-flex>
                                <!-- สถานะขอคืนภาษี -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="statusetax"
                                    :items="liststatusetax"
                                    item-value="statusetax_value"
                                    item-text="statusetax_type"
                                    :label="$t('myinboxPage.conditionOptional.taxrefund')"
                                    prepend-icon="reorder"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                    multiple
                                  >
                                    <template v-slot:prepend-item>
                                      <v-list-item ripple @click="fn_selectall_statusetax">
                                        <v-list-item-action>
                                          <v-icon :color="statusetax.length > 0 ? 'indigo darken-4' : ''">
                                            {{ icon_ }}
                                          </v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            Select All
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-divider class="mt-2"></v-divider>
                                    </template>
                                  </v-select>
                                </v-flex>
                                <!-- chanel -->
                                <!-- <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="chanel"
                                    :items="listDoctype"
                                    item-value="document_type"
                                    item-text="document_type"
                                    :label="$t('myinboxPage.conditionOptional.chanel')"
                                    prepend-icon="reorder"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  ></v-select>
                                </v-flex> -->
                              </v-layout>
                            </v-container>
                          </v-flex>

                          <v-flex xs12 sm12 md1 lg1 class="text-center">
                            <v-divider vertical></v-divider>
                          </v-flex>

                          <v-flex xs12 sm12 md5 lg5 class="pa-1 text-center">
                            <v-container fluid>
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documentid"
                                    :label="$t('myinboxPage.conditionOptional.filedocumentid')"
                                    prepend-icon="format_list_numbered"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documentidto"
                                    :label="$t('myinboxPage.conditionOptional.todateupload')"
                                    prepend-icon="format_list_numbered"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <!-- ช่องกรอก เอกสารลงวันที่ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogFromdatedocument"
                                    v-model="modalFromdatedocument"
                                    :return-value.sync="fromdatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.fromdatedocument')"
                                        v-model="fromdatedocument"
                                        @click:prepend="modalFromdatedocument = true"
                                        prepend-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="fromdate_hint"
                                        @click:clear="todatedocument = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="fromdatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="changeFromdatedocument()"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn dark color="error" @click.stop="modalFromdatedocument = false">{{
                                        $t("myinboxPage.conditionOptional.closefromdatedocument")
                                      }}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>

                                <!-- ช่องกรอก เอกสารลงวันที่ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogTodatedocument"
                                    v-model="modalTodatedocument"
                                    :return-value.sync="todatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        readonly
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.todatedocument')"
                                        v-model="todatedocument"
                                        prepend-icon="event"
                                        @click:prepend="modalTodatedocument = true"
                                        v-on="on"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="todate_hint"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="$refs.dialogTodatedocument.save(todatedocument)"
                                      :min="fromdatedocument"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn dark color="error" @click.stop="modalTodatedocument = false">{{
                                        $t("myinboxPage.conditionOptional.closetodatedocument")
                                      }}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                              </v-layout>

                              <!-- ช่องกรอก SellerTaxID -->
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sellertaxid"
                                    :label="$t('myinboxPage.conditionOptional.sellertaxid')"
                                    prepend-icon="mdi-domain"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sellerbranchid"
                                    :label="$t('myinboxPage.conditionOptional.sellerbranchid')"
                                    prepend-icon="mdi-format-list-bulleted-square"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>

                              <!-- ช่องกรอก BuyerTaxID -->
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="buyertaxid"
                                    :label="$t('myinboxPage.conditionOptional.buyertaxid')"
                                    prepend-icon="mdi-domain"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="buyerbranchid"
                                    :label="$t('myinboxPage.conditionOptional.buyerbranchid')"
                                    prepend-icon="mdi-format-list-bulleted-square"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-flex>

              <v-flex xs12 lg12 class="text-center">
                <br />
                <v-btn
                    outlined
                    class="ma-1"
                    color="#2D3B4E"
                    :disabled="loaddataprogress"
                    :loading="loaddataprogress"
                    @click="Clear_data()"
                      >
                      <v-icon left>mdi-refresh</v-icon>
                        {{ $t("myinboxPage.clear_data") }}
                    </v-btn>
                <v-btn
                  class="ma-1 white--text"
                  :color="color.theme"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="searchdocument()"
                >
                  <v-icon left>search</v-icon>
                  &nbsp;
                  {{ $t("myinboxPage.search") }}
                </v-btn>
                <v-menu :close-on-content-click="closeOnContentClick">
                  <!-- <template v-slot:activator="{ on }"> -->
                  <template v-slot:activator="{ on }">
                    <v-btn
                      outlined
                      class="ma-1 white-text"
                      :color="color.theme"
                      :disabled="loaddataprogress"
                      :loading="loaddataprogress"
                      v-on="on"
                    >
                      {{ $t("dowloads") }}
                      <v-icon>keyboard_arrow_down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template>
                       <v-container fluid>
                      <!-- <v-list-item> -->
                        <v-checkbox v-model="typedownloads" class="mt-0" label="Summary File Duplicate"></v-checkbox>
                        <v-checkbox v-model="typeitem" class="mt-0" label="Summary Item (1 ไฟล์ 1 บรรทัด)"></v-checkbox>
                      <!-- </v-list-item> -->
                      </v-container>
                    </template>
                    <v-tooltip bottom content-class="mt-n13" v-model="showToolTipExportFile" :disabled="!disableDataStorageOverload">
                      <template v-slot:activator="{ on }">
                        <v-list-item
                          @click="(disableByPackage || disableDataStorageOverload ? '' : exportfile()) ,closeOnContentClick = true"
                          outlined
                          v-on="on"
                          :disabled="disableByPackage"
                          :ripple="!disableDataStorageOverload"
                          @mouseover="show_download_all = true"
                          @mouseleave="show_download_all = false" 
                        >
                          <v-list :disabled="disableDataStorageOverload">
                            <v-list-item-title >
                              <v-icon left :color="color.theme">mdi-archive-arrow-down</v-icon>
                              {{ "&nbsp;&nbsp;&nbsp;" + $t("myinboxPage.export") }}
                            </v-list-item-title>
                          </v-list>
                              <v-expand-transition  >  
                                <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-lock</v-icon>
                                    </template>
                                      <span>{{$t('balloon.lock_function')}}</span>
                                    </v-tooltip>
                                </v-overlay>
                              </v-expand-transition>
                            <template v-if="!disableByPackage && !disableDataStorageOverload">
                              <v-badge v-if="$t('default') === 'th'"
                                style="z-index:10000;bottom:-27px;left:-145px;"
                                :value="show_download_all"
                                :content="$t('balloon.download_all')"
                                transition="slide-y-transition"
                              ></v-badge>
                              <v-badge v-else
                                style="z-index:10000;bottom:-27px;left:-125px;"
                                :value="show_download_all"
                                :content="$t('balloon.download_all')"
                                transition="slide-y-transition"
                              ></v-badge>
                            </template>
                        </v-list-item>
                      </template>
                        <span>{{ $t("myinboxPage.storageisfull") }}</span>
                    </v-tooltip>
                    <!-- @click="exportfilecsv()" -->
                    <!-- @click="fn_setheader('csv')" -->
                    <v-tooltip bottom content-class="mt-n13" v-model="showToolTipExportFilecsv" :disabled="!disableDataStorageOverload">
                      <template v-slot:activator="{ on }">
                        <v-list-item
                          @click="(disableByPackage || disableDataStorageOverload ? '' : exportfilecsv()), closeOnContentClick = true"
                          outlined
                          v-on="on"
                          :disabled="disableByPackage"
                          :ripple="!disableDataStorageOverload"
                          @mouseover="show_download_all_csv = true"
                          @mouseleave="show_download_all_csv = false"
                        >
                          <v-list :disabled="disableDataStorageOverload">
                            <v-list-item-title>
                              <v-icon left :color="color.theme">insert_drive_file</v-icon>
                              {{ "&nbsp;&nbsp;&nbsp;" + $t("myinboxPage.exportcsv") }}
                            </v-list-item-title>
                          </v-list>
                                <v-expand-transition>
                                  <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon 
                                        v-bind="attrs"
                                        v-on="on"
                                        >mdi-lock</v-icon>
                                      </template>
                                      <span>{{$t('balloon.lock_function')}}</span>
                                    </v-tooltip>
                                  </v-overlay>
                                </v-expand-transition>
                              <template v-if="!disableByPackage && !disableDataStorageOverload">
                                <v-badge v-if="$t('default') === 'th'"
                                  style="z-index:10000;bottom:-27px;left:-197px;"
                                  :value="show_download_all_csv"
                                  :content="$t('balloon.download_all_csv')"
                                  transition="slide-y-transition"
                                ></v-badge>
                                <v-badge v-else
                                  style="z-index:10000;bottom:-27px;left:-175px;"
                                  :value="show_download_all_csv"
                                  :content="$t('balloon.download_all_csv')"
                                  transition="slide-y-transition"
                                ></v-badge>
                              </template>
                        </v-list-item>
                      </template>
                        <span>{{ $t("myinboxPage.storageisfull") }}</span>
                    </v-tooltip>
                    <!-- @click="fn_setheader('excel')" -->
                    <!-- @click="exportfileexcel()" -->
                    <v-tooltip bottom content-class="mt-n13" v-model="showToolTipExportFileExcel" :disabled="!disableDataStorageOverload">
                      <template v-slot:activator="{ on }">
                        <v-list-item
                          @click="(disableByPackage || disableDataStorageOverload ? '' : exportfileexcel()), closeOnContentClick = true"
                          outlined
                          v-on="on"
                          :disabled="disableByPackage"
                          :ripple="!disableDataStorageOverload"
                          @mouseover="show_download_all_excel = true"
                          @mouseleave="show_download_all_excel = false"
                        >
                          <v-list :disabled="disableDataStorageOverload">
                            <v-list-item-title>
                              <v-icon left :color="color.theme">description</v-icon>
                              {{ "&nbsp;&nbsp;&nbsp;" + $t("myinboxPage.exportexcel") }}
                            </v-list-item-title>
                          </v-list>
                          <v-expand-transition>
                            <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                              <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-lock</v-icon>
                                    </template>
                                      <span>{{$t('balloon.lock_function')}}</span>
                                    </v-tooltip>
                            </v-overlay>
                        </v-expand-transition>
                        <template v-if="!disableByPackage && !disableDataStorageOverload">
                          <v-badge v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-27px;left:-210px;"
                            :value="show_download_all_excel"
                            :content="$t('balloon.download_all_excel')"
                            transition="slide-y-transition"
                          ></v-badge>
                          <v-badge v-else
                            style="z-index:10000;bottom:-27px;left:-190px;"
                            :value="show_download_all_excel"
                            :content="$t('balloon.download_all_excel')"
                            transition="slide-y-transition"
                          ></v-badge>
                        </template>
                        </v-list-item>
                      </template>
                        <span>{{ $t("myinboxPage.storageisfull") }}</span>
                    </v-tooltip>
                  </v-list>
                </v-menu>
              </v-flex>
              <v-flex xs12 lg12 class="ma-2">
                <v-layout>
                  <v-flex xs6 lg4>
                    <v-select
                      class="pr-2"
                      prepend-inner-icon="sort"
                      hide-details
                      solo
                      dense
                      v-model="size"
                      :items="listsize"
                      item-text="value"
                      item-disabled="disable"
                      @change="clickpagination()"
                    >
                    <template v-slot:item="{ item, on, attrs }">                      
                      <v-list-item v-bind="attrs" v-on="on" :item-color="color.theme" :ripple="!item.disable">
                        {{ item.value }}
                      <v-expand-transition>
                        <v-overlay absolute color="grey lighten-1" :value="item.disable">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                >mdi-lock</v-icon>
                            </template>
                                <span>{{$t('balloon.lock_function')}}</span>
                          </v-tooltip>
                        </v-overlay>
                      </v-expand-transition>
                      </v-list-item>
                    </template>
                    </v-select>
                  </v-flex>
                  <v-flex xs6 lg4>
                    <v-select
                      class="pr-2"
                      @change="clickstatus(statusde)"
                      v-model="statusde"
                      hide-details
                      solo
                      dense
                      :items="liststatus"
                    ></v-select>
                  </v-flex>
                  <!-- <v-flex xs3 lg2>
                    <v-btn @click="openreletion = true" :color="color.theme" :dark="color.darkTheme">{{$t("myinboxPage.create_relation")}}</v-btn>
                  </v-flex> -->
                  <v-flex xs6 lg-1>
                    <!-- <v-btn
                      id="btn"
                      class="ml-2"
                      :color="color.theme"
                      :dark="color.darkTheme"
                      v-show="checktaxidcentral"
                      @click="fn_gotoresend()"
                      >B2P</v-btn
                    >
                  </v-flex>

                   <v-flex xs12 offset-lg8 lg2 class="text-xs-center text-right">
                    <b>
                      <span>{{ totalfile + ' ' + $t('myinboxPage.items') || 0 + ' ' + $t('myinboxPage.items')}}</span>
                    </b>
                    <br />-->
                  </v-flex>
                  <v-flex lg-4 class=" pr-2 hidden-md-and-down" v-if="sortby === 'status'">
                    <v-alert
                      dense
                      height="41"
                      v-model="clickSortorderby"
                      :color="color.alertSort"
                      dark
                      v-show="clickSortorderby"
                      style="margin-bottom: 0%;"
                    >
                      <div>
                        <v-icon :color="color.alertText" v-if="checkpagination === true">mdi-chevron-up</v-icon>
                        <v-icon :color="color.alertText" v-else-if="checkpagination === false">mdi-chevron-down</v-icon>
                        <span :style="colorSort" v-if="sortby === 'status'">{{ $t("Filter.status") }}</span>
                        <v-btn
                          width="24"
                          height="24"
                          style="float: right;"
                          text
                          fab
                          x-small
                          :color="color.alertSort"
                          dark
                          @click="closeclicksort"
                        >
                          <v-icon :color="color.alertText">mdi-close-circle</v-icon>
                        </v-btn>
                      </div>
                    </v-alert>
                  </v-flex>
                  <v-flex lg4 class="pr-2 hidden-md-and-down" v-else>
                    <v-alert
                      dense
                      height="41"
                      v-model="clickSort"
                      :color="color.alertSort"
                      dark
                      v-show="clickSort"
                      style="margin-bottom: 0%;"
                    >
                      <div>
                        <v-icon :color="color.alertText" v-if="multiSort === true">mdi-chevron-up</v-icon>
                        <v-icon :color="color.alertText" v-else-if="multiSort === false">mdi-chevron-down</v-icon>
                        <span :style="colorSort" v-if="sortby === 'name'">{{ $t("Filter.nameonly") }}</span>
                        <span :style="colorSort" v-else-if="sortby === 'docdate'">{{ $t("Filter.docdate") }}</span>
                        <span :style="colorSort" v-else-if="sortby === 'docno'">{{ $t("Filter.docno") }}</span>
                        <span :style="colorSort" v-else-if="sortby === 'inboxdate'">{{ $t("Filter.inboxdate") }}</span>
                        <span :style="colorSort" v-else-if="sortby === 'doctype'">{{ $t("Filter.doctype") }}</span>
                        <span :style="colorSort" v-else-if="sortby === 'sendname'">{{ $t("Filter.sendname") }}</span>
                        <span :style="colorSort" v-else-if="sortby === 'receivername'">{{ $t("Filter.receivername") }}</span>
                        <v-btn
                          width="24"
                          height="24"
                          style="float: right;"
                          text
                          fab
                          x-small
                          :color="color.alertSort"
                          dark
                          @click="checkSort"
                        >
                          <v-icon :color="color.alertText">mdi-close-circle</v-icon>
                        </v-btn>
                      </div>
                    </v-alert>
                  </v-flex>
                </v-layout>
                <v-layout>
                  <!-- ปุ่มเมนู อันเก่า -->
                   <v-flex xs4 lg4>
                    <!-- <div v-if="statusmutipledownload === false" class="pt-2">
                      <v-btn
                        @mouseover="show_menu = true"
                        @mouseleave="show_menu = false"
                        :disabled="sortStatus==='pending'"
                        :color="color.theme"
                        rounded
                        class="ma-1 white--text"
                        @click="statusmutipledownload = true,show_menu = false"
                        fab
                        small
                      >
                        <v-icon>apps</v-icon>&nbsp;
                        {{ $t('myinboxPage.multipledownload')}} ไม่ใช้
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_menu"
                          content="Menu"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                    </div>
                    <div v-else class="pt-2">
                      <v-btn 
                      @mouseover="show_download = true"
                      @mouseleave="show_download = false"
                      fab 
                      small 
                      color="primary" 
                      class="ma-1" 
                      @click="multipledownload()">
                        <v-icon>mdi-download-multiple</v-icon>
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_download"
                          content="Multiple Download"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                      <v-btn
                        @mouseover="show_delete = true"
                        @mouseleave="show_delete = false"
                        fab
                        small
                        color="error"
                        class="ma-1"
                        @click="multipleDelete()"
                        v-if="status_permission_inbox === 'AD'"
                      >
                        <v-icon color="white">delete</v-icon>
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:-60px;"
                          :value="show_delete"
                          content="Multiple Delete"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                      <v-btn                      
                        @mouseover="show_print = true"
                        @mouseleave="show_print = false"
                        fab
                        small
                        color="#5875D3"
                        class="white--text ma-1 pa-0"
                        @click="fn_printPDF()"
                      >
                        <v-icon>print</v-icon>
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:-40px;"
                          :value="show_print"
                          content="print PDF"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                      <v-btn
                        @mouseover="show_clear = true"
                        @mouseleave="show_clear = false"
                        fab
                        small
                        :color="color.theme"
                        :dark="color.darkTheme"
                        class="ma-1"
                        @click="(statusmutipledownload = false), clearmultipledownload(), show_clear = false"
                      >
                        <v-icon>clear</v-icon>
                        <v-badge
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_clear"
                          content="Clear"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                    </div>-->
                  </v-flex>
                  <v-flex xs8 lg8 class="text-right">
                    <v-chip class="ma-2" :color="color.theme" :text-color="color.chipText">
                      <v-icon left>mdi-file</v-icon>
                      {{ totalfile + " " + $t("myinboxPage.items") || 0 + " " + $t("myinboxPage.items") }}
                    </v-chip>
                  </v-flex>
                </v-layout>
                <!-- Sort File -->
                <v-flex lg12 xs12>
                <v-layout row wrap class="pl-3 pr-1"   >
                  <v-flex lg8 xs8>
                  </v-flex>
                  <v-flex lg4 offset-lg8 offset-xs4 class="pl-3 pr-0">
                    <v-list nav :color="color.BG" class="py-0">
                      <v-list-item>
                        <v-layout>                
                          <v-flex class="text-right" lg12>
                            <v-menu offset-y rounded="lg">
                              <template v-slot:activator="{ attrs, on }">
                                <v-btn 
                                  small rounded
                                  class="white--text"
                                  :color="color.theme" 
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                <v-icon small>mdi-sort</v-icon>
                                  {{ $t("sorting.sort") }}: 
                                  <div class="font-weight-bold">
                                    {{ $t(sorting['sort'].find(x => x.id === sort_by).text) }}, {{ $t(sorting['direction'].find(x => x.id === direction).text) }}
                                  </div>
                                </v-btn>
                              </template>
                              <v-list dense nav>
                                <v-list-item-group
                                  v-model="sort_by"
                                  active-class="border"
                                  :color="color.theme"
                                >
                                  <template v-for="(item, i) in sorting['sort']">
                                    <v-list-item
                                      :key="`item-${i}`"
                                      :value="item.id"
                                    >
                                      <template>
                                        <v-list-item-content>
                                          <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>
                                  </template>
                                </v-list-item-group>
                                <v-divider class="mt-2" />
                                <v-list-item-group
                                  class="mt-4"
                                  v-model="direction"
                                  active-class="border"
                                  :color="color.theme"
                                >
                                  <template v-for="(item, i) in sorting['direction']">
                                    <v-list-item
                                      :key="`item-${i}`"
                                      :value="item.id"
                                    >
                                      <template>
                                        <v-list-item-content>
                                          <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
                                        </v-list-item-content>
                                      </template>
                                    </v-list-item>
                                  </template>
                                </v-list-item-group>
                              </v-list>
                            </v-menu>
                          </v-flex>
                        </v-layout>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                </v-layout>
                </v-flex>
                <!-- <v-layout>
                  <v-flex xs2 lg2>
                      <v-select
                        outlined
                        dense
                        v-model="multistatus_etax"
                        label="สถานะขอคืนภาษี"
                        :items="liststatusetax"
                        item-value="statusetax_value"
                        item-text="statusetax_type"
                        @change="fn_changestatusetax_multi(inboxdata,multistatus_etax)"
                      ></v-select>
                  </v-flex>
                </v-layout> -->
              </v-flex>

              <v-flex xs12 lg12 class="ma-2" v-if="loaddataprogress === true">
                <div class="text-center">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>
              </v-flex>
              <v-flex xs12 lg12 v-else class="ma-2">
                <div v-if="resolutionScreen <= 500">
                  <v-list two-line class="mb-6 pb-6 elevation-1" v-if="inboxdata.length !== 0">
                    <v-list-item v-for="item in inboxdata" :key="item.title" style="cursor: pointer;">
                      <v-list-item-avatar v-show="statusmutipledownload">
                        <v-checkbox v-model="filemultipledownload" :value="item" v-if="item.trash !== 'DELETE'" />
                      </v-list-item-avatar>
                      <v-list-item-avatar>
                        <v-icon large :color="seticon_new(item.data_type)[1]" style="font-weight: 100">{{
                          seticon_new(item.data_type)[0]
                        }}</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-content @click="previewfile(item)">
                        <v-list-item-title v-text="item.filename"></v-list-item-title>
                        <v-list-item-subtitle v-text="item.document_no"></v-list-item-subtitle>
                        <div>
                          <v-chip
                            x-small
                            dark
                            :color="
                              item.status === 'pending'
                                ? '#ffcc00'
                                : item.status === 'reject'
                                ? 'red'
                                : item.status === 'unsend'
                                ? '#ff8533'
                                : item.status === 'request'
                                ? 'primary'
                                : item.status === 'request_delete_log'
                                ? '#FF0000'
                                : item.status === 'pending_receiver_stoage_full'
                                ? '#ffcc00'
                                : item.status === 'duplicate'
                                ? 'primary'
                                : 'success'
                            "
                            >{{ item.status === "request_delete_log" ? "ขอลบ" : item.status }}</v-chip
                          >
                          <v-icon v-if="item.trash === 'DELETE'" color="red" small>cancel_presentation</v-icon>
                          <v-icon v-else-if="item.trash === 'T'" color="amber" small>delete_sweep</v-icon>
                        </div>
                      </v-list-item-content>
                      <v-list-item-action>
                        <!-- @click="downloadfile(item)" -->
                        <v-menu>
                          <template v-slot:activator="{ on }">
                            <v-btn class="elevation-0" fab small v-on="on" outlined :disabled="item.status === 'pending'">
                              <v-icon>more_vert</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="previewfile(item)">
                              <v-list-item-icon>
                                <v-icon>visibility</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{ $t("currentRClick.preview") }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="(opendialog = true), (newdata = item)" :disabled="disableDataStorageOverload">
                              <v-list-item-icon>
                                <v-icon>mdi-information</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{ $t("myinboxPage.Filedetails") }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <!-- <v-list-item @click="opendialogtrue = true, newdata=item">
                              <v-list-item-icon>
                                <v-icon>folder</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('myinboxPage.Relateddocument')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item> -->
                            <!-- @click="disableByPackage ? '' : downloadfile(item)" 
                            :ripple="!disableByPackage" 
                              :disabled="disableByPackage" -->
                             <v-tooltip bottom attach content-class="mt-n14" v-model="showToolTipDownloadFile" :disabled="!disableDataStorageOverload" >
                              <template v-slot:activator="{ on }">
                            <v-list-item 
                              @click="disableDataStorageOverload ? '' : downloadfile(item)" 
                              v-if="item.trash !== 'DELETE'" 
                              :ripple="!disableDataStorageOverload" 
                              v-on="on"
                              :disabled="disableDataStorageOverload"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-download-multiple</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{ $t("dowloads") }}</v-list-item-title>
                              </v-list-item-content>
                              <!-- <v-expand-transition>
                                <v-overlay absolute color="grey lighten-1" :value="disableDataStorageOverload">
                                  <span>{{ $t("myinboxPage.storageisfull") }}</span>
                                </v-overlay>
                              </v-expand-transition>                             -->
                            </v-list-item>
                            </template>
                            <span>{{ $t("myinboxPage.storageisfull") }}</span>
                            </v-tooltip>
                            <!-- ลบลงถังขยะ -->
                            <v-list-item @click="fn_confirmdelete(item)" v-if="status_permission_inbox === 'AD'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{ $t("checkdelete.delete") }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <!-- check ขอลบ -->
                            <!-- <v-list-item v-if="item.status !== 'request_delete_log'" @click="opendialogdelete = true ,data=item ,statusdelete='N'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('checkdelete.deleteonlyme')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item> -->
                            <!-- <v-list-item v-if="item.status !== 'request_delete_log'" @click="opendialogdelete = true ,data=item ,statusdelete='Y'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>{{$t('checkdelete.deleteandrequest')}}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item> -->
                            <!-- ตั้งค่าเวลาหมดอายุ -->
                            <v-list-item @click="fn_openset(), (newdata = item)">
                              <v-list-item-icon>
                                <v-icon>mdi-folder-clock</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                ตั้งค่าวันหมดอายุ
                              </v-list-item-content>
                            </v-list-item>
                            <!-- ยืนยัน request_delete_log -->
                            <v-list-item v-if="item.status === 'request_delete_log'">
                              <v-list-item-icon>
                                <v-icon>delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="pr-2">
                                <v-btn
                                  color="green"
                                  dark
                                  dense
                                  @click="(opendialogrequestdelete = true), (data = item), (statusrequestdelete = 'Y')"
                                  >ยืนยัน</v-btn
                                >
                              </v-list-item-content>
                              <v-list-item-content>
                                <v-btn color="red" dark dense @click="deleterequest(item, 'N')">ไม่ยืนยัน</v-btn>
                              </v-list-item-content>
                            </v-list-item>

                            <!-- active -->
                            <v-list-item
                              v-if="
                                (item.status === 'pending' && item.trash === 'T') ||
                                  (item.status === 'pending' && item.trash === null)
                              "
                            >
                              <v-list-item-icon>
                                <v-icon>insert_drive_file</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content class="pr-2">
                                <v-btn color="success" dense @click="approvefile(item)">Active</v-btn>
                              </v-list-item-content>
                              <v-list-item-content>
                                <v-btn color="red" dark outlined dense @click="rejectfile(item)">Reject</v-btn>
                              </v-list-item-content>
                            </v-list-item>
                            <!-- pending -->
                            <v-list-item v-if="item.status === 'reject'">
                              <v-list-item-icon>
                                <v-icon>insert_drive_file</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-btn color="#ffcc00" dark dense @click="pendingfile(item)">Pending</v-btn>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="item.status === 'request' && item.trash !== 'DELETE' && item.trash !== 'T'">
                              <div class="pr-3 pl-2">
                                <v-btn color="amber" dark @click="btnunsend(item)">Unsend</v-btn>
                              </div>
                              <div>
                                <v-btn class color="red" dark @click="btnnotapprove(item)">No</v-btn>
                              </div>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>
                    <!-- 
                    <v-divider inset></v-divider>-->
                  </v-list>
                  <v-list v-else class="elevation-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
            <div v-else>
                <v-layout class="mb-2">
                  <div class="ml-2">
                    <div v-if="statusmutipledownload === false" class="pt-0">
                      <v-btn
                        @mouseover="show_menu = true"
                        @mouseleave="show_menu = false"
                        :disabled="sortStatus==='pending'"
                        :color="color.theme"
                        rounded
                        class="ma-1 white--text"
                        @click="statusmutipledownload = true,show_menu = false"
                      >
                        <v-icon>mdi-menu</v-icon>&nbsp;
                        <p class="ml-2" style="font-size:15px;margin-bottom:2px;">{{$t('balloon.menu')}}</p>
                        <!-- {{ $t('myinboxPage.multipledownload')}} -->
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-40px;"
                          :value="show_menu"
                          :content="$t('balloon.menu')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-55px;"
                          :value="show_menu"
                          :content="$t('balloon.menu')"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                      
                    <!-- <v-chip outlined  class="pl-9" style="margin-left:-30px" :color="color.theme" >  
                    {{$t('balloon.menu')}}
                    </v-chip> -->
                    </div>
                    <div v-else class="pt-2">
                      <!-- :color="disableDataStorageOverload ? '#D6D6D6' : 'primary'" 
                        @click="disableDataStorageOverload || disableByPackage ? '' : multipledownload()"
                        :ripple="!disableDataStorageOverload || !disableByPackage" -->
                      <v-btn 
                        @mouseover="show_download = true"
                        @mouseleave="show_download = false"
                        fab 
                        small 
                        class="white--text ma-1" 
                        :color="disableDataStorageOverload ? '#D6D6D6' : 'primary'" 
                        @click="disableDataStorageOverload ? '' : multipledownload()"
                        :ripple="!disableDataStorageOverload"
                      >
                      <v-icon :disabled="disableDataStorageOverload">mdi-download-multiple</v-icon>
                        <!-- <v-icon>mdi-download-multiple</v-icon> -->
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_download"
                          :content="disableDataStorageOverload ? $t('myinboxPage.storageisfull') : $t('balloon.download')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_download"
                          :content="disableDataStorageOverload ? $t('myinboxPage.storageisfull') : $t('balloon.download')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <!-- <v-expand-transition>
                          <v-overlay absolute color="grey lighten-1" style="border-radius: 50%; width: 100%; height: 160%; margin-top: -7px;" :value="disableByPackage">
                            <v-icon>mdi-lock</v-icon>
                          </v-overlay>
                        </v-expand-transition> -->
                      </v-btn>
                      <v-btn
                        @mouseover="show_delete = true"
                        @mouseleave="show_delete = false"
                        fab
                        small
                        color="error"
                        class="ma-1"
                        @click="multipleDelete()"
                        v-if="status_permission_inbox === 'AD'"
                      >
                        <v-icon color="white">delete</v-icon>
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-22px;"
                          :value="show_delete"
                          :content="$t('balloon.delete')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-34px;"
                          :value="show_delete"
                          :content="$t('balloon.delete')"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                      <v-btn                      
                        @mouseover="show_print = true"
                        @mouseleave="show_print = false"
                        fab
                        small
                        :color="disableDataStorageOverload ? '#D6D6D6' : '#5875D3'"
                        class="white--text ma-1 pa-0"
                        @click="disableDataStorageOverload ? '' : fn_printPDF()"
                        :ripple="!disableDataStorageOverload"
                      >
                        <v-icon :disabled="disableDataStorageOverload">print</v-icon>
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-38px;"
                          :value="show_print"
                          :content="disableDataStorageOverload ? $t('myinboxPage.storageisfull') : $t('balloon.print')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-42px;"
                          :value="show_print"
                          :content="disableDataStorageOverload ? $t('myinboxPage.storageisfull') : $t('balloon.print')"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                      <v-btn
                        @mouseover="show_clear = true"
                        @mouseleave="show_clear = false"
                        fab
                        small
                        :color="color.theme"
                        :dark="color.darkTheme"
                        class="ma-1"
                        @click="(statusmutipledownload = false), clearmultipledownload(), show_clear = false"
                      >
                        <v-icon>clear</v-icon>
                        <v-badge v-if="$t('default') === 'th'"
                          style="z-index:10000;bottom:-45px;left:-20px;"
                          :value="show_clear"
                          :content="$t('balloon.clear')"
                          transition="slide-y-transition"
                        ></v-badge>
                        <v-badge v-else
                          style="z-index:10000;bottom:-45px;left:-30px;"
                          :value="show_clear"
                          :content="$t('balloon.clear')"
                          transition="slide-y-transition"
                        ></v-badge>
                      </v-btn>
                    </div>
                  </div>

                <v-flex d-flex justify-center>
                  <v-pagination
                    class="mr-14"
                    v-model="page"
                    :length="pageCount"
                    :color="color.theme"
                    :total-visible="10"
                    @input="clickpagination()"
                  ></v-pagination>
                  </v-flex>
                </v-layout>
                  <!-- panigation อันเก่า  -->
                  <!-- <div class="text-xs-center ma-2">
                  <br />
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :color="color.theme"
                    :total-visible="10"
                    @input="clickpagination()"
                  ></v-pagination>
                </div> -->
                  <!-- {{inboxdata}} -->
                  <v-data-table
                    v-model="filemultipledownload"
                    :headers="headers"
                    :items="inboxdata"
                    item-key="file_id"
                    :search="search"
                    class="elevation-1"
                    hide-default-footer
                    :single-select="false"
                    :show-select="statusmutipledownload"
                    :page.sync="page"
                    :items-per-page="size"
                    @page-count="pageCount = $event"
                  >
                    <template v-slot:[`header.data_type`]="{ header }">
                      <span
                        :style="headerTable"
                        @click="(sortby = 'status'), (clickSortorderby = true), (checkpagination = !checkpagination), check()"
                        >{{ header.text }}</span
                      >
                    </template>
                    <template v-slot:[`header.filename`]="{ header }">
                      <span
                        class="pointer"
                        @click="(sortby = 'name'), (clickSort = true), (multiSort = !multiSort), sortFile(inboxdata)"
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                    </template>
                    <template v-slot:[`header.document_no`]="{ header }">
                      <span
                        class="pointer"
                        @click="(sortby = 'docno'), (clickSort = true), (multiSort = !multiSort), sortFile(inboxdata)"
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                    </template>
                    <template v-slot:[`header.document_date`]="{ header }">
                      <span
                        class="pointer"
                        @click="(sortby = 'docdate'), (clickSort = true), (multiSort = !multiSort), sortFile(inboxdata)"
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                    </template>
                    <template v-slot:[`header.document_type`]="{ header }">
                      <span
                        class="pointer"
                        @click="(sortby = 'doctype'), (clickSort = true), (multiSort = !multiSort), sortFile(inboxdata)"
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                    </template>
                    <template v-slot:[`header.sender_name`]="{ header }">
                      <span
                        class="pointer"
                        @click="(sortby = 'sendname'), (clickSort = true), (multiSort = !multiSort), sortFile(inboxdata)"
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                    </template>
                    <!-- <template v-slot:header.receiver_name="{ header }">
                        <span class="pointer" @click="sortby='receivername',clickSort=true,multiSort=!multiSort,sortFile(inboxdata)  "
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                    </template> -->
                    <template v-slot:[`header.cre_dtm`]="{ header }">
                      <span
                        class="pointer"
                        @click="(sortby = 'inboxdate'), (clickSort = true), (multiSort = !multiSort), sortFile(inboxdata)"
                        :style="headerTable"
                        >{{ $t(header.text) }}</span
                      >
                    </template>
                    <!-- สถานะขอคืนภาษี -->
                    <template v-slot:[`header.tax_refund`]="{ header }">
                      <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
                      <!-- <v-icon small>mdi-arrow-down-drop-circle-outline</v-icon> -->
                       <v-select
                        outlined
                        dense
                        v-model="multistatus_etax"
                        label="ตั้งค่าสถานะขอคืนภาษี"
                        :items="liststatusetax"
                        item-value="statusetax_value"
                        item-text="statusetax_type"
                        :disabled="sortStatus==='pending'"
                        @change="fn_changestatusetax_multi(inboxdata,multistatus_etax)"
                      ></v-select>
                     
                    </template>

                    <template v-slot:[`header.file_id`]="{ header }">
                      <span :style="headerTable">{{ $t(header.text) }}</span>
                    </template>

                    <template v-slot:item="props">
                      <tr @dblclick="previewfile(props.item)">
                        <td width="1%" class="text-center" v-show="statusmutipledownload">
                          <v-checkbox v-model="filemultipledownload" :value="props.item" v-if="props.item.trash !== 'DELETE' && props.item.status !== 'pending' " />
                        </td>
                        <td width="5%" class="text-center">
                          <div>
                            <v-badge color="red" overlap icon="clear" v-if="props.item.trash === 'DELETE'">
                              <v-icon large :color="seticon_new(props.item.data_type)[1]">{{
                                seticon_new(props.item.data_type)[0]
                              }}</v-icon>
                            </v-badge>
                            <v-badge color="amber" overlap icon="delete_sweep" v-else-if="props.item.trash === 'T'">
                              <v-icon large :color="seticon_new(props.item.data_type)[1]">{{
                                seticon_new(props.item.data_type)[0]
                              }}</v-icon>
                            </v-badge>
                            <!-- <v-badge
                              color="primary"
                              overlap
                              icon="mdi-folder-clock"
                              v-else-if="props.item.expired_inbox !== '' "
                            >
                              <v-icon
                                large
                                :color="seticon(props.item.data_type)[1]"
                              >{{ seticon(props.item.data_type)[0]}}</v-icon>
                            </v-badge> -->
                            <v-icon v-else large :color="seticon_new(props.item.data_type)[1]">{{
                              seticon_new(props.item.data_type)[0]
                            }}</v-icon>

                            <v-chip
                              x-small
                              dark
                              :color="
                                props.item.status === 'pending'
                                  ? '#ffcc00'
                                  : props.item.status === 'reject'
                                  ? 'red'
                                  : props.item.status === 'unsend'
                                  ? '#ff8533'
                                  : props.item.status === 'request'
                                  ? 'primary'
                                  : props.item.status === 'request_delete_log'
                                  ? '#FF0000'
                                  : props.item.status === 'pending_receiver_stoage_full'
                                  ? '#ffcc00'
                                  : props.item.status === 'duplicate'
                                  ? 'primary'
                                  : 'success'
                              "
                              >{{ props.item.status === "request_delete_log" ? "ขอลบ" : props.item.status }}</v-chip
                            >
                          </div>
                        </td>
                        <td width="5%" :title="props.item.filename" v-if="dataAccountActive.type === 'Citizen' || feature.filename === 'Y'">{{ props.item.filename | subStr }}</td>
                        <td width="10%" class="text-center" v-if="dataAccountActive.type === 'Citizen' || feature.document_no === 'Y'">{{ props.item.document_no | subStr }}</td>
                        <td width="15%" class="text-center" v-if="dataAccountActive.type === 'Citizen' || feature.document_date === 'Y'">
                          {{ setformatdate(props.item.document_date) }}
                          <!-- <v-icon v-if="props.item.expired_inbox !== null " color="error">
                            mdi-folder-clock
                          </v-icon> -->
                        </td>
                        <td width="10%" class="text-center" v-if="dataAccountActive.type === 'Citizen' || feature.document_type === 'Y'">
                          <v-chip default :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'">{{ props.item.document_type }}</v-chip>
                        </td>
                        <td width="10%" class="text-center" v-if="dataAccountActive.type === 'Citizen' || feature.seller_name === 'Y'">
                          <v-chip default :color="$vuetify.theme.dark ? '#1E1E1E' : 'white'">{{ props.item.sender_name }}</v-chip>
                        </td>
                        <!-- <td width="10%" class="text-center">{{props.item.receiver_name}}</td> -->
                        <td width="15%" class="text-center" v-if="dataAccountActive.type === 'Citizen' || feature.cre_dtm === 'Y'">{{ formatdatetime(props.item.cre_dtm) }}</td>
                        <td width="15%" class="text-center" v-if="dataAccountActive.type === 'Citizen' || feature.status_onechat === 'Y'">
                          <v-select
                            dense
                            outlined
                            class="text-center ma-2"
                            v-model="props.item.tax_refund"
                            :items="liststatusetax"
                            item-value="statusetax_value"
                            item-text="statusetax_type"
                            :disabled="props.item.status === 'pending'"
                            @change="fn_changestatusetax(props.item, props.item.tax_refund)"
                          ></v-select>
                        </td>
                        <td width="5%" class="text-center">
                          <v-menu top offset-y>
                            <template v-slot:activator="{ on }" >
                              <v-btn class="elevation-0" fab small v-on="on" outlined :disabled="props.item.status === 'pending'">
                                <v-icon>more_vert</v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="previewfile(props.item)">
                                <v-list-item-icon>
                                  <v-icon>visibility</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{ $t("currentRClick.preview") }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- รายละเอียดไฟล์ -->                            
                              <v-tooltip bottom attach content-class="mt-n14" v-model="showToolTipFileDetail" :disabled="!disableDataStorageOverload">
                                <template v-slot:activator="{ on }">
                                  <v-list-item
                                    @click="(disableDataStorageOverload ? '' : opendialog = true), (newdata = props.item)"
                                    :ripple="!disableDataStorageOverload"
                                    v-on="on"
                                  >
                                    <v-list-item class="pa-0" :disabled="disableDataStorageOverload">
                                      <v-list-item-icon>
                                        <v-icon>mdi-information</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>{{ $t("myinboxPage.Filedetails") }}</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list-item>
                                </template>
                                  <span>{{ $t("myinboxPage.storageisfull") }}</span>
                              </v-tooltip>
                              <!-- เอกสารที่เกี่ยวข้อง -->
                              <!-- <v-list-item @click=" opendialogtrue = true, newdata=props.item">
                                <v-list-item-icon>
                                  <v-icon>folder</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('myinboxPage.Relateddocument')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                              <v-tooltip bottom attach content-class="mt-n14" v-model="showToolTipDownloadFile" :disabled="!disableDataStorageOverload" >
                                <template v-slot:activator="{ on }">
                                  <!-- @click="disableDataStorageOverload || disableByPackage ? '' : downloadfile(props.item)"
                                    :ripple="!disableDataStorageOverload || !disableByPackage" -->
                                  <v-list-item
                                    v-if="props.item.trash !== 'DELETE'"
                                    @click="disableDataStorageOverload ? '' : downloadfile(props.item)"
                                    v-on="on"
                                    :ripple="!disableDataStorageOverload" 
                                  >
                                    <!-- v-if="props.item.trash === 'T' || props.item.trash === alive" -->
                                    <!-- <v-list-item class="pa-0" :disabled="disableDataStorageOverload || disableByPackage"> -->
                                    <v-list-item class="pa-0" :disabled="disableDataStorageOverload">
                                      <v-list-item-icon>
                                        <v-icon>mdi-download-multiple</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-content>
                                        <v-list-item-title>{{ $t("dowloads") }}</v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <!-- <v-expand-transition>
                                      <v-overlay absolute color="grey lighten-1" :value="disableByPackage">
                                        <v-icon>mdi-lock</v-icon>
                                      </v-overlay>
                                    </v-expand-transition> -->
                                  </v-list-item>
                                </template>
                                  <span>{{ $t("myinboxPage.storageisfull") }}</span>
                              </v-tooltip>
                              <!-- ลบลงถังขยะ -->
                              <v-list-item @click="fn_confirmdelete(props.item)" v-if="status_permission_inbox === 'AD'">
                                <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{ $t("checkdelete.delete") }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- ปุ่มการขอลบ ของตัวเอง-->
                              <!-- <v-list-item
                                v-if="props.item.status !== 'request_delete_log' "
                                @click="opendialogdelete = true ,data=props.item ,statusdelete='N'"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('checkdelete.deleteonlyme')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                              <!-- ปุ่มลบของตัวเองและrequest -->
                              <!-- <v-list-item
                                v-if="props.item.status !== 'request_delete_log' "
                                @click="opendialogdelete = true ,data=props.item ,statusdelete='Y'"
                              >
                                <v-list-item-icon>
                                  <v-icon>delete_forever</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>{{$t('checkdelete.deleteandrequest')}}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                              <v-divider v-if="props.item.status === 'request_delete_log'"></v-divider>
                              <v-list-item v-if="props.item.status === 'request_delete_log'">
                                <v-list-item-icon>
                                  <v-icon>
                                    delete
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn
                                    @click="(opendialogrequestdelete = true), (data = props.item), (statusrequestdelete = 'Y')"
                                    color="green"
                                    dark
                                  >
                                    <v-list-item-title>
                                      Accept
                                    </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                                <v-list-item-content class="pl-2">
                                  <v-btn @click="deleterequest(props.item, 'N')" color="red" dark>
                                    <v-list-item-title>
                                      Reject
                                    </v-list-item-title>
                                  </v-btn>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider v-if="props.item.status === 'request' && props.item.trash === 'alive'"></v-divider>
                              <v-list-item v-if="props.item.status === 'request' && props.item.trash === 'alive'">
                                <div class="pr-3 pl-2">
                                  <v-btn color="amber" dark @click="btnunsend(props.item)">Unsend</v-btn>
                                </div>
                                <div>
                                  <v-btn class color="red" dark @click="btnnotapprove(props.item)">No</v-btn>
                                </div>
                              </v-list-item>
                              <!-- active -->
                              <v-list-item
                                v-if="
                                  (props.item.status === 'pending' && props.item.trash === 'T') ||
                                    (props.item.status === 'pending' && props.item.trash === null)
                                "
                              >
                                <v-list-item-icon>
                                  <v-icon>insert_drive_file</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content class="pr-2">
                                  <v-btn color="success" dense @click="approvefile(props.item)">Active</v-btn>
                                </v-list-item-content>
                                <v-list-item-content>
                                  <v-btn color="red" dark outlined dense @click="rejectfile(props.item)">Reject</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- pending -->
                              <v-list-item v-if="props.item.status === 'reject'">
                                <v-list-item-icon>
                                  <v-icon>insert_drive_file</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-btn color="#ffcc00" dark dense @click="pendingfile(props.item)">Pending</v-btn>
                                </v-list-item-content>
                              </v-list-item>
                              <!-- ตั้งค่าเวลาหมดอายุ -->
                              <!-- <v-list-item @click="fn_openset(), (newdata = props.item)">
                                <v-list-item-icon>
                                   <v-icon>mdi-folder-clock</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                   ตั้งค่าวันหมดอายุ
                                </v-list-item-content>
                              </v-list-item> -->
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </template>
                    <!-- <template  v-slot:item="{ items }">
                      <tbody>
                        <tr
                          v-for="(item, index) in items"
                          :key="index"
                          @dblclick="previewfile(item)"
                          style="cursor: pointer;"
                        >
                        <td class="text-center" v-show="statusmutipledownload">
                            <v-checkbox v-model="filemultipledownload" :value="item" />
                          </td>
                          <td class="text-center">
                            <v-icon
                              large
                              :color="seticon(item.data_type)[1]"
                            >{{ seticon(item.data_type)[0]}}</v-icon>
                          </td>
                          <td>{{ item.filename}}</td>
                          <td>{{ item.document_no}}</td>
                          <td class="text-center">{{ setformatdate(item.document_date)}}</td>
                          <td class="text-center">{{ item.document_type }}</td>
                          <td class="text-center">{{item.sender_name}}</td>
                          <td class="text-center">{{ formatdatetime(item.cre_dtm)}}</td>
                          <td class="text-center">
                            <v-btn class="elevation-0" fab small @click="downloadfile(item)">
                              <v-icon>mdi-download</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>-->
                  </v-data-table>
                </div>
                <div class="text-xs-center">
                  <br />
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                    :color="color.theme"
                    :total-visible="10"
                    @input="clickpagination()"
                  ></v-pagination>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
          <previewfile
            :arrayfile="inboxdata"
            :show="openpreviewfile"
            :filedata_1="currentfile"
            @closepreviewfile="openpreviewfile = false"
            @openpreviewfile="openpreviewfile = true"
          ></previewfile>
          <dialogdetailinbox :show="opendialog" @closedialog="opendialog = false" :fileinbox="newdata" />
          <dialogdetail
            :show="opendialogtrue"
            @closedialog="(opendialogtrue = false), getSourceSystem()"
            :fileinbox="newdata"
            :filedata="inboxdata"
          />
          <dialogrelation :show="openreletion" @closedialog="openreletion = false" @loaddata="getSourceSystem()" />
          <inboxapprove :show="openinbox" :inboxdata="inboxdata" @closedialoginbox="openinbox = false" />
          <dialogcheckdelete
            :show="opendialogdelete"
            @closedialog="opendialogdelete = false"
            @reload="getSourceSystem()"
            :data="data"
            :status="statusdelete"
          ></dialogcheckdelete>

          <dialogcheckrequestdelete
            :show="opendialogrequestdelete"
            @closedialog="opendialogrequestdelete = false"
            @reload="getSourceSystem()"
            :data="data"
            :status="statusrequestdelete"
          ></dialogcheckrequestdelete>

          <dialogsnackbermultidowload
            :show="opensnackbarmultidownload"
            @closedialog="opensnackbarmultidownload = false"
            :percent="percent"
            :date="datetime"
            :namedowload="namedowload"
            :btsdownload="btsdownload"
            :count_loadingfile="count_loadingfile"
            :count_allfile="count_allfile"
            :statusloading="statusloading"
          ></dialogsnackbermultidowload>
          <dialogmultideleteinboxoutbox
            :show="opensdialogmultidelinboxoutbox"
            @closedialog="(opensdialogmultidelinboxoutbox = false), (filemultipledownload = [])"
            @closedialogreload="
              (opensdialogmultidelinboxoutbox = false), (filemultipledownload = []), loadstorage(), getSourceSystem()
            "
            :filedata="filemultipledownload"
          ></dialogmultideleteinboxoutbox>
          <dialogedit
            :show="opendialogedit"
            :filedata="newdata"
            @closedialog="opendialogedit = false"
            @closedialogandrefresh="getSourceSystem(), (opendialogedit = false)"
          />
          <dialogcheckdeleterightclick
            :show="opensdialogcheckdeleterightclick"
            @closedialog="(opensdialogcheckdeleterightclick = false), (listdata = '')"
            @closedialogreload="(opensdialogcheckdeleterightclick = false), (listdata = ''), loadstorage(), getSourceSystem()"
            :filedata="listdata"
          ></dialogcheckdeleterightclick>
          <dialogresend
            :show="opendialogresend"
            :taxid_biz="taxid_biz"
            :biz_id="biz_id"
            @closedialog="opendialogresend = false"
          ></dialogresend>
          <dialogconfirmdelete
            :filedata="listdata"
            :show="confirmdelete"
            @closedialog="confirmdelete = false"
            @reload="fn_deletetotrash(listdata)"
          />
          <dialogselectheader
            :show="opendialogselectheader"
            @closedialog="opendialogselectheader = false"
            @selectheaderbeforedownload="checkdownloadcsvandexcel"
          ></dialogselectheader>
          <dialogsnackbarloading_print
            :show="opensnackbarloadingprint"
            @closedialog="opensnackbarloadingprint = false"
            :percent="percent"
            :date="datetime"
          ></dialogsnackbarloading_print>                 
           <!-- snackbardowload show dowloadfilemulti error -->
          <div class="text-center">
              <v-snackbar :style="`padding-top: ${i * 90}px`" v-for="(s,i) in snackbars" :key="i" v-model="snackbar_download_error"  :timeout="20000" top right>
                 <v-list style="padding-top: 0px;">
                <v-list-item dense style="height:40px; width:450px;" three-line>
                  <v-list-item-icon>
                     <v-icon class="mt-6" large color="red">mdi-alert-octagon-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> {{ s.filename }}</v-list-item-title>
                    <v-list-item-subtitle>{{"errorMessage : " +  s.error }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn class="mt-2" color="red" text @click="hide(i)" fab small >
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              </v-snackbar>
          </div>
          <!-- snackbardowload -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowload" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
                  {{ $t("myinboxPage.dowload.dowloading") }}
                  <v-spacer></v-spacer>
                  <v-btn color="red" @click="snackbardowload = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title>{{ InboxFile }} {{ datetime }}</v-list-item-title>
                  </v-list-item-content>
                  <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                    >&nbsp; {{ percentCompleted }}</v-progress-circular
                  >
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>

          <!-- snackbardowloadfile 1 file -->
          <!-- <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonefile" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
                  {{$t('myinboxPage.dowload.dowloading')}}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonefile = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title>{{namedowload}}</v-list-item-title>
                  </v-list-item-content>
                  <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular>
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div> -->

          <!-- snackbardowloadfileทั้งหมด -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonetotal" :timeout="timeout" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
                  {{ $t("myinboxPage.dowload.dowloading") }}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonetotal = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <!-- <v-list-item-title style="color:black">{{ ExportInboxFiles }}</v-list-item-title> -->
                    <v-list-item-title style="color:black">{{ this.datenow + '_InboxFiles' }}</v-list-item-title>
                    <v-list-item-subtitle class="pa-1">
                      <v-progress-linear :value="percentCompleted" color="teal"></v-progress-linear>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-icon v-if="percentCompleted === 100" color="green">mdi-check-bold</v-icon>
                  <!-- <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular> -->
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>

          <!-- snackbardowloadfilecsv ทั้งหมด -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonetotalcsv" :timeout="timeout" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
                  {{ $t("myinboxPage.dowload.dowloading") }}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonetotalcsv = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title style="color:black">{{ ExportInboxFilescsv }}</v-list-item-title>
                    <v-list-item-subtitle class="pa-1">
                      <v-progress-linear :value="percentCompleted" color="teal"></v-progress-linear>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-icon v-if="percentCompleted === 100" color="green">mdi-check-bold</v-icon>
                  <!-- <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular> -->
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>

          <!-- snackbardowloadfileexcel ทั้งหมด -->
          <div class="text-center ma-2">
            <v-snackbar v-model="snackbardowloadonetotalexcel" :timeout="timeout" bottom color="white">
              <v-list color="white" style="padding-top: 0px;">
                <v-toolbar :color="color.theme" dense short :dark="color.darkTheme" flat fab>
                  {{ $t("myinboxPage.dowload.dowloading") }}
                  <v-spacer></v-spacer>
                  <v-btn color="red" text @click="snackbardowloadonetotalexcel = false" fab x-small>
                    <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-list-item dense style="height:40px; width:400px;">
                  <v-list-item-content>
                    <v-list-item-title style="color:black">{{ ExportInboxFilesexcel }}</v-list-item-title>
                    <v-list-item-subtitle class="pa-1">
                      <v-progress-linear :value="percentCompleted" color="teal"></v-progress-linear>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-icon v-if="percentCompleted === 100" color="green">mdi-check-bold</v-icon>
                  <!-- <v-progress-circular
                    :rotate="90"
                    :size="35"
                    :width="3"
                    :value="percentCompleted"
                    color="teal"
                    style="margin-left:25px"
                  >&nbsp; {{ percentCompleted }}</v-progress-circular> -->
                </v-list-item>
              </v-list>
            </v-snackbar>
          </div>
        </v-card-text>
      </v-card>
    </v-content>
  </v-app>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import { saveAs } from "file-saver";
//   import onebox_toolbar from "../components/layout/layout-toolbar-new";
import onebox_toolbar_inboxoutbox from "../components/layout/layout-inboxoutbox-toolbar";
import format from "date-fns/format";
import { set } from "date-fns";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";

// const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
const previewfile = () => import("../components/optional/dialog-preview");
const inboxapprove = () => import("../components/optional/dialog-inboxapproved");
const dialogdetailinbox = () => import("../components/optional/dialog-detailinbox");
const dialogdetail = () => import("../components/optional/dialog-detail");
const dialogrelation = () => import("../components/optional/dialog-insertrelation");
const dialogcheckdelete = () => import("../components/optional/dialog-checkdelete");
const dialogcheckrequestdelete = () => import("../components/optional/dialog-checkrequestdelete");
const dialogsnackbermultidowload = () => import("../components/optional/dialog-snackbarmultidowmload");
const dialogedit = () => import("../components/optional/dialog-editexpire-in-out");
const dialogmultideleteinboxoutbox = () => import("../components/optional/dialog-multideletetotrashinboxoutbox");
const dialogcheckdeleterightclick = () => import("../components/optional/dialog-checkdeleterightclick");
const dialogconfirmdelete = () => import("../components/optional/dialog-confirmdelete");
const dialogresend = () => import("../components/optional/dialog-resend.vue");
const dialogselectheader = () => import("../components/optional/dialog-selectheader.vue");
const dialogsnackbarloading_print = () => import("../components/optional/dialog-snackbarloadding-print.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  name: "myinbox_sourcesystem",
  components: {
    onebox_toolbar_inboxoutbox,
    previewfile,
    dialogdetailinbox,
    dialogdetail,
    inboxapprove,
    dialogrelation,
    dialogcheckdelete,
    dialogcheckrequestdelete,
    dialogsnackbermultidowload,
    dialogedit,
    dialogmultideleteinboxoutbox,
    dialogcheckdeleterightclick,
    dialogconfirmdelete,
    dialogresend,
    dialogselectheader,
    dialogsnackbarloading_print
  },
  data: function() {
    return {
      statusloading:"",
      count_loadingfile:0,
      count_allfile:0,
      closeOnContentClick:false,
      typedownloads:false,
      typeitem:false,
      showToolTipExportFile: false,
      showToolTipExportFilecsv: false,
      showToolTipExportFileExcel: false,
      showToolTipFileDetail: false,
      showToolTipDownloadFile: false,
      opensnackbarloadingprint:false,
      loaddataprogress_print:false,
      show_menu: false,
      show_download: false,
      show_delete: false,
      show_print: false,
      show_clear: false,
      show_download_all: false,
      show_download_all_csv: false,
      show_download_all_excel: false,
      sorting: {
        sort: [{
            id: 'document_date',
            text: 'sorting.document_date'
          }, {
            id: 'document_type',
            text: 'sorting.document_type'
          }, {
            id: 'cre_dtm',
            text: 'sorting.cre_dtm'
          }, {
            id: 'customer_name',
            text: 'sorting.customer_name'
          },{
            id: 'saler_name',
            text: 'sorting.saler_name'
          },{
             id: 'filename',
            text: 'sorting.filename'
          },{
            id: 'document_no',
            text: 'sorting.doc_no'
          },{
            id: 'status',
            text: 'sorting.status'
          }
        ],
        direction: [{
            id: 'ASC',
            text: 'sorting.ascending'
          }, {
            id: 'DESC',
            text: 'sorting.descending'
          }
        ]
      },
      sort_by: "filename",
      direction: "ASC",
      datenow: format(new Date(), "dd_MM_yyyy"), 
      timeout: -1,
      snackbar_download_error:false,
      snackbars:[],
      snackbar:{},
      multiLine: true,
      filename_error:'',
      errorMessage:'',
      snackbar:false,
      multistatus_etax:"",
      status_etax: "",
      statusetax: [],
      listheader: [],
      typedownload: "",
      opendialogselectheader: false,
      maxDisplayfileextension: 1,
      maxDisplay: 2,
      biz_id: "",
      checkallbiz: "",
      taxid_biz: "",
      checkdata: "",
      confirmdelete: false,
      listdata: "",
      opensdialogcheckdeleterightclick: false,
      btsdownload: false,
      opensdialogmultidelinboxoutbox: false,
      opendialogedit: false,
      opensnackbarmultidownload: false,
      percent: 0,
      data: "",
      statusdelete: "",
      statusrequestdelete: "",
      clickSortorderby: false,
      checkpagination: false,
      openreletion: false,
      openinbox: false,
      newdata: [],
      opendialog: false,
      opendialogtrue: false,
      opendialogdelete: false,
      opendialogrequestdelete: false,
      opendialogresend: false,
      items: [{ title: "รายละเอียดไฟล์" }, { title: "เอกสารที่เกี่ยวข้อง" }, { title: "ดาวน์โหลด" }],
      listcsv: [],
      namedowload: "",
      InboxFile: "InboxFile",
      ExportInboxFiles: "ExportInboxFiles",
      ExportInboxFilescsv: "ExportInboxFilescsv",
      ExportInboxFilesexcel: "ExportInboxFilesexcel",
      percentCompleted: 0,
      datetime: "",
      snackbardowload: false,
      snackbardowloadonefile: false,
      snackbardowloadonetotal: false,
      snackbardowloadonetotalcsv: false,
      snackbardowloadonetotalexcel: false,
      search: "",
      filemultipledownload: [],
      statusmutipledownload: false,
      liststatus: ["All","Active", "Pending", "Duplicate", "request for removal" ,"resend from etax"],
      // liststatus: ["All", "Active", "Pending", "Reject", "Request", "Unsend","Duplicate"],      
      defaulticon: "",
      listtaxid: [
        {
          taxid: "0105517005330",
          namebiz: "บริษัท สรรพสินค้าเซ็นทรัล จำกัด",
        },
        {
          taxid: "0105511004676",
          namebiz: "บริษัท ห้างเซ็นทรัล ดีพาทเมนท์สโตร์ จำกัด",
        },
        {
          taxid: "0105511004650",
          namebiz: "บริษัท เตียง จิราธิวัฒน์ จำกัด",
        },
        {
          taxid: "0105558163762",
          namebiz: "บริษัท เซ็นทรัล เพย์เม้นท์ จำกัด",
        },
        {
          taxid: "0107562000386",
          namebiz: "บริษัท เซ็นทรัล รีเทล คอร์ปอเรชั่น จำกัด (มหาชน)",
        },
        {
          taxid: "0105535133093",
          namebiz: "บริษัท เซ็นทรัลแฟมิลี่มาร์ท จำกัด",
        },
        {
          taxid: "0105538032743",
          namebiz: "บริษัท บีทูเอส จำกัด",
        },
        {
          taxid: "0105544030277",
          namebiz: "บริษัท ซี อาร์ ซี เพาเวอร์ รีเทล จำกัด",
        },
        {
          taxid: "0105532021090",
          namebiz: "บริษัท เซ็นทรัล เรสตอรองส์ กรุ๊ป จำกัด",
        },
        {
          taxid: "0105547039585",
          namebiz: "บริษัท ซีอาร์จี อินเตอร์เนชั่นแนล ฟู้ด จำกัด",
        },
        {
          taxid: "0105537143215",
          namebiz: "บริษัท ออฟฟิศเมท (ไทย) จำกัด",
        },
        {
          taxid: "0105559006539",
          namebiz: "บริษัท เซ็นทรัล กรุ๊ป ออนไลน์ จำกัด",
        },
        {
          taxid: "0105558057016",
          namebiz: "บริษัท เซ็นเนอร์จี อินโนเวชั่น จำกัด",
        },
        {
          taxid: "0105539138812",
          namebiz: "บริษัท ซี อาร์ ซี สปอร์ต จำกัด",
        },
        {
          taxid: "0105560148929",
          namebiz: "บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด",
        },
        {
          taxid: "0105550099674",
          namebiz: "บริษัท เทราซอฟท์ โซลูชั่นส์ ดีเวลอปเม้นท์  จำกัด",
        },
        {
          taxid: "0105536044868",
          namebiz: "บริษัท อาร์ ไอ เอส จำกัด",
        },
        {
          taxid: "0905538002731",
          namebiz: "บริษัท ซีอาร์ หาดใหญ่ (ประเทศไทย) จำกัด",
        },
        {
          taxid: "0415538000849",
          namebiz: "บริษัท ซีอาร์ อุดรธานี (ประเทศไทย) จำกัด",
        },
        {
          taxid: "0505538005335",
          namebiz: "บริษัท ซีอาร์ เชียงใหม่ (ประเทศไทย) จำกัด",
        },
        {
          taxid: "0705538001125",
          namebiz: "บริษัท ซีอาร์ ราชบุรี (ประเทศไทย) จำกัด",
        },
        {
          taxid: "0105538069213",
          namebiz: "บริษัท ซีอาร์ (ประเทศไทย)  จำกัด",
        },
        {
          taxid: "0105554147665",
          namebiz: "บริษัท แมทเทอร์ ดี จำกัด",
        },
        {
          taxid: "0125558018410",
          namebiz: "บริษัท เซ็นทรัล และ มัทสึโมโตะ คิโยชิ จำกัด",
        },
        {
          taxid: "0105547041601",
          namebiz: "บริษัท เซ็นทรัล อินชัวรันส์ เซอร์วิสเซส จำกัด",
        },
        {
          taxid: "0105557044735",
          namebiz: "บริษัท หลักทรัพย์นายหน้าซื้อขายหน่วยลงทุน เซ็นทรัล เวลธ์ โซลูชั่น จำกัด",
        },
        {
          taxid: "0225539000097",
          namebiz: "บริษัท ซีอาร์ จันทบุรี (ประเทศไทย) จำกัด",
        },
        {
          taxid: "0107536000412",
          namebiz: "บริษัท โรบินสัน จำกัด (มหาชน)",
        },
        {
          taxid: "0105553072530",
          namebiz: "บริษัท เซ็นทรัลแอมบาสซีโฮเต็ล จำกัด",
        },
        {
          taxid: "0105561194797",
          namebiz: "บริษัท เดอะวันเซ็นทรัล จำกัด",
        },
        {
          taxid: "0105536071903",
          namebiz: "บริษัท ปริญญ์ อินเตอร์เทรด จำกัด",
        },
        {
          taxid: "0105521017584",
          namebiz: "บริษัท แบรนด์สมิท จำกัด",
        },
        {
          taxid: "0105531038428",
          namebiz: "บริษัท ห้องอาหาร ซี.ดี.เอส จำกัด",
        },
        {
          taxid: "0105500001158",
          namebiz: "บริษัท เซ็นทรัลเทรดดิ้ง จำกัด",
        },
        {
          taxid: "0105515001451",
          namebiz: "บริษัท เซ็นทรัลกาเมนท์แฟคทอรี่ จำกัด",
        },
        {
          taxid: "0105521007996",
          namebiz: "บริษัท เท็กซ์ทรัล เท็กซ์ไทลส์ จำกัด",
        },
        {
          taxid: "0105537127643",
          namebiz: "บริษัท เซ็นทรัลมาร์เก็ตติ้งกรุ๊ป จำกัด",
        },
        {
          taxid: "0105521019188",
          namebiz: "บริษัท ซีเทรคสากล จำกัด",
        },
        {
          taxid: "0125557004849",
          namebiz: "บริษัท เมพ คอร์ปอเรชั่น จำกัด",
        },
        {
          taxid: "0105518005643",
          namebiz: "บริษัท ปีเตอร์แอนด์จินนี่ จำกัด",
        },
        {
          taxid: "0105524005030",
          namebiz: "บริษัท เฟ็ดเดอริค เวอลด์เซลส์ จำกัด",
        },
        {
          taxid: "0105552007696",
          namebiz: "บริษัท ไทย วัตตส์ จำกัด",
        },
        {
          taxid: "0105527031261",
          namebiz: "บริษัท อัพ ฟรอนท์ จำกัด",
        },
        {
          taxid: "0105561088148",
          namebiz: "บริษัท ฟิวเจอร์พลัส จำกัด",
        },
        {
          taxid: "0105539113917",
          namebiz: "บริษัท ออฟฟิศเมท ออมนิแฟรนไชส์ จำกัด",
        },
        {
          taxid: "0105552051814",
          namebiz: "บริษัท ทรี พลัส บริการ จำกัด",
        },
        {
          taxid: "0105520002419",
          namebiz: "บริษัท เพลินฤดี จำกัด",
        },
        {
          taxid: "0105536110682",
          namebiz: "บริษัท เซ็นทรัลมาร์เก็ตติ้งกรุ๊ป อินเตอร์เทรด จำกัด",
        },
        {
          taxid: "0105532053242",
          namebiz: "บริษัท สวนนาเกลือ จำกัด",
        },
        {
          taxid: "0105532071615",
          namebiz: "บริษัท เซ็นทรัลธนบุรี จำกัด",
        },
        {
          taxid: "0105546106190",
          namebiz: "บริษัท ป่าตัน 1 จำกัด",
        },
        {
          taxid: "0105546133715",
          namebiz: "บริษัท ป่าตัน 2 จำกัด",
        },
        {
          taxid: "0105546133723",
          namebiz: "บริษัท ป่าตัน 3 จำกัด",
        },
        {
          taxid: "0105546133243",
          namebiz: "บริษัท ป่าตัน 4 จำกัด",
        },
        {
          taxid: "0105551040525",
          namebiz: "บริษัท เซ็นทรัล พีเพิล ดีเวลลอปเม้นท์ เซ็นเตอร์ จำกัด",
        },
        {
          taxid: "0105561095357",
          namebiz: "บริษัท เซ็นทรัลทำ วิสาหกิจเพื่อสังคม จำกัด",
        },
        {
          taxid: "0105536144455",
          namebiz: "บริษัท เดอะลิตเทิล คิทเช่น จำกัด",
        },
        {
          taxid: "0105531000641",
          namebiz: "บริษัท จิวเวลรี่ เรียลตี้ จำกัด",
        },
        {
          taxid: "0105554076601",
          namebiz: "บริษัท เฟลาเออร์ ชอพ จำกัด",
        },
        {
          taxid: "0105555142730",
          namebiz: "บริษัท ไดนิ่ง เซนเซชั่นส์ จำกัด",
        },
        {
          taxid: "0105534097280",
          namebiz: "บริษัท โอเวอร์ซีส์ แฟชั่นส์ จำกัด",
        },
        {
          taxid: "0107551000134",
          namebiz: "บริษัท ซีโอแอล จำกัด (มหาชน)",
        },
        {
          taxid: "0105555174178",
          namebiz: "บริษัท มูจิ รีเทล (ประเทศไทย) จำกัด",
        },
        {
          taxid: "0105539004352",
          namebiz: "บริษัท เพาเวอร์บาย จำกัด",
        },
        {
          taxid: "0105555021215",
          namebiz: "บริษัท ซีอาร์ซี ไทวัสดุ จำกัด",
        },
        {
          taxid: "0105535134278",
          namebiz: "บริษัท เซ็นทรัล ฟู้ด รีเทล จำกัด",
        },
        {
          taxid: "0105551120171",
          namebiz: "บริษัท เอเชีย มัลติเพิล จำกัด",
        },
        {
          taxid: "0105561097538",
          namebiz: "บริษัท ที่นี่ วงศ์สว่าง จำกัด",
        },
        {
          taxid: "0105561097619",
          namebiz: "บริษัท ที่นี่ ขอนแก่น จำกัด",
        },
        {
          taxid: "0105536137947",
          namebiz: "บริษัท บราเดอร์ โฮลดิ้ง จำกัด",
        },
        {
          taxid: "0105534115695",
          namebiz: "บริษัท แวนเทจ กราวด์ จำกัด",
        },
        {
          taxid: "0105536024352",
          namebiz: "บริษัท ซี.อาร์.จี.บริการ จำกัด",
        },
        {
          taxid: "0105542031772",
          namebiz: "บริษัท จักรวรรดิ โฮลดิ้ง จำกัด",
        },
        {
          taxid: "0105534118864",
          namebiz: "บริษัท ภูเก็ตฮิลล์ รีสอร์ท จำกัด",
        },
        {
          taxid: "0105537113138",
          namebiz: "บริษัท ซีดีเอส.โฮลดิ้ง จำกัด",
        },
        {
          taxid: "0105532035686",
          namebiz: "บริษัท ระยอง ซีวิว จำกัด",
        },
        {
          taxid: "0105532021693",
          namebiz: "บริษัท ชะอำ ซีวิว จำกัด",
        },
        {
          taxid: "0105531041551",
          namebiz: "บริษัท โปรเจ็คพัฒนา จำกัด",
        },
        {
          taxid: "0105536107045",
          namebiz: "บริษัท เซ็นทรัล โฮลดิ้ง จำกัด",
        },
        {
          taxid: "0105528029049",
          namebiz: "บริษัท อินฟินิตี้ แอสเซท จำกัด",
        },
        {
          taxid: "0105543042221",
          namebiz: "บริษัท สร้างสรรค์ธุรกิจ จำกัด",
        },
        {
          taxid: "0105521007571",
          namebiz: "บริษัท เซ็นทรัลอินเตอร์พัฒนา จำกัด",
        },
        {
          taxid: "0105561070095",
          namebiz: "บริษัท เซ็นทรัล เจดี มันนี่ จำกัด",
        },
        {
          taxid: "0105562174668",
          namebiz: "บริษัท กรีน ฟู้ด แฟคทอรี่ จำกัด",
        },
      ],
      listExtensionFile: [
        // {
        //   data_type: "pdf",
        // },
        // {
        //   data_type: "xml",
        // },
      ],
      listDoctype:[],
      // listDoctype: [
      //   {
      //     document_type: "ใบรับ (ใบเสร็จรับเงิน)",
      //   },
      //   {
      //     document_type: "ใบเสร็จรับเงิน/ใบกำกับภาษี",
      //   },
      //   {
      //     document_type: "ใบลดหนี้",
      //   },
      //   {
      //     document_type: "ใบเพิ่มหนี้",
      //   },
      // ],
      currentfile: {},
      openpreviewfile: false,
      nodata: this.$t("myinboxPage.Nodata"),
      panel: [0],
      filename: "",
      fileextension: [],
      documentid: "",
      documentidto: "",
      documenttype: [],
      sendername: "",
      systemname: "",
      sellertaxid: "",
      sellerbranchid: "",
      receivername: "",
      buyertaxid: "",
      buyerbranchid: "",
      fromdateupload: "",
      modalFromdateupload: false,
      todateupload: "",
      modalTodateupload: false,
      fromdatedocument: "",
      modalFromdatedocument: false,
      todatedocument: "",
      modalTodatedocument: false,
      loaddataprogress: false,
      inboxdata: [],
      // headers: [
      //   {
      //     text: "#",
      //     align: "center",
      //     sortable: false,
      //     value: "data_type",
      //     width: "5%",
      //   },
      //   {
      //     text: "tablefile.filenameonly",
      //     lign: "left",
      //     value: "filename",
      //     sortable: false,
      //     width: "10%",
      //   },
      //   {
      //     text: "tablefile.docno",
      //     align: "center",
      //     value: "document_no",
      //     sortable: false,
      //     width: "5%",
      //   },
      //   {
      //     text: "tablefile.docdate",
      //     align: "center",
      //     value: "document_date",
      //     sortable: false,
      //     width: "5%",
      //   },
      //   {
      //     text: "tablefile.doctype",
      //     value: "document_type",
      //     align: "center",
      //     sortable: false,
      //     width: "10%",
      //   },
      //   {
      //     text: "tablefile.sendername",
      //     value: "sender_name",
      //     align: "center",
      //     sortable: false,
      //     width: "10%",
      //   },
      //   // {
      //   //   text: "tablefile.receivername",
      //   //   value: "receiver_name",
      //   //   align: "left",
      //   //   sortable: false,
      //   //   width: "10%"
      //   // },
      //   {
      //     text: "tablefile.inboxdate",
      //     value: "cre_dtm",
      //     align: "center",
      //     sortable: false,
      //     width: "10%",
      //   },
      //   {
      //     text: "สถานะขอคืนภาษี",
      //     value: "tax_refund",
      //     align: "center",
      //     sortable: false,
      //     width: "15%",
      //   },
      //   {
      //     text: "tablefile.option",
      //     value: "file_id",
      //     align: "left",
      //     sortable: false,
      //     width: "5%",
      //   },
      // ],
      fromdate: "",
      fromdate_hint: "",
      todate_hint: "",
      size: 10,
      statusde: "All",
      sortStatus: "",
      page: 1,
      totalfile: 0,
      sortby: "",
      clickSort: false,
      folderSort: [],
      fileSort: [],
      sortdata: [],
      buff: [],
      multiSort: false,
      liststatusetax: [
        {
          statusetax_type: "ขอคืนภาษีแล้ว",
          statusetax_value: "Y",
        },
        {
          statusetax_type: "ยังไม่ขอคืนภาษี",
          statusetax_value: "N",
        },
      ],
      feature: {},
      // package: "",
    };
  },
  computed: {
    ...mapState([
      "status_permission_outbox",
      "status_permission_inbox",
      "username",
      "authorize",
      "account_active",
      "service",
      "color",
      "storage_usage_biz",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.totalfile;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    checktaxidcentral() {
      // console.log("taxid----",this.dataAccountActive.taxid);
      // console.log("listtax",this.listtaxid);
      // console.log("this.$route.params.biz",this.$route.query);

      //เช็คว่ากดมาจากปุ่ม inbox หรือ inbox(ทุกบริษัท) เช็คจาก $route.query.biz
      if (this.$route.query.biz === "" || this.$route.query.biz === null || this.$route.query.biz === undefined) {
        //ไม่ส่ง biz มาคือกดจากเมนู inbox เอา taxid ไปเช็คกับ list taxid centralเลย
        this.checkdata = this.listtaxid.findIndex((v) => v.taxid === this.dataAccountActive.taxid);
        if (this.checkdata === -1) {
          return false;
        } else {
          return true;
        }
      } else {
        //ส่งbiz มาคือกดจากเมนูinbox(ทุกบริษัท)
        //เอา biz ที่ส่งไปเช็คใน dataBusinessProfile
        this.checkallbiz = this.dataBusinessProfile.findIndex((v) => v.business_id === this.$route.query.biz);
        if (this.checkallbiz !== -1) {
          //ถ้าเจอ เอาไปตำแหน่งหา taxid แล้วเอา taxid เช็คกับ list taxid central ว่าอยู่ในเครือcentralมั้ย
          let taxidbiz = this.dataBusinessProfile[this.checkallbiz].id_card_num;
          this.checkdata = this.listtaxid.findIndex((v) => v.taxid === taxidbiz);
          if (this.checkdata === -1) {
            return false;
          } else {
            return true;
          }
        }
      }
    },

    parentfolder() {
      return this.dataAccountActive["directory_id"];
    },
    Labeldata: {
      get() {
        console.log("เข้า", this.$route.query.type);
        if (this.$route.query.type === 2 || this.$route.query.type === "2") {
          let companyname = "";
          if (this.$route.query.type === 2 || this.$route.query.type === "2") {
            this.defaulticon = "business";
            if (this.$t("default") === "th") {
              if (
                this.$route.query.nameth === undefined ||
                this.$route.query.nameth === null ||
                this.$route.query.nameth === ""
              ) {
                companyname = this.dataAccountActive["business_info"]["first_name_th"] || "-";
              } else {
                companyname = this.$route.query.nameth;
              }
            } else {
              if (
                this.$route.query.nameen === undefined ||
                this.$route.query.nameen === null ||
                this.$route.query.nameen === ""
              ) {
                companyname = this.dataAccountActive["business_info"]["first_name_eng"] || "-";
              } else {
                companyname = this.$route.query.nameen;
              }
            }
                        
            return this.$t("toolbar.myinbox.Businessname") + " " + companyname;
            //  this.getSourceSystem()
          } else {
            this.defaulticon = "face";
            //  this.getSourceSystem()
            return this.$t("toolbar.myinbox.Personalname");
          }
          //  this.getSourceSystem()
        } else {
          this.defaulticon = "face";
          //  this.getSourceSystem()
          return this.$t("toolbar.myinbox.Personalname");
        }
      },
    },
    showdatafilter() {
      return this.inboxdata.filter((item) => {
        return item.filename.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    icon() {
      if (this.Alldoctype) return "mdi-close-box";
      if (this.Somedoctype) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    icons() {
      if (this.Allfileextension) return "mdi-close-box";
      if (this.Somefileextension) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    icon_() {
      if (this.Allstatusetax) return "mdi-close-box";
      if (this.Somestatusetax) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    Alldoctype() {
      return this.documenttype.length === this.listDoctype.length;
    },
    Somedoctype() {
      return this.documenttype.length > 0 && !this.Alldoctype;
    },
    Allfileextension() {
      console.log("this.fileextension.length", this.fileextension.length);
      console.log("this.listExtensionFile.length", this.listExtensionFile.length);
      return this.fileextension.length === this.listExtensionFile.length;
    },
    Somefileextension() {
      return this.fileextension.length > 0 && !this.Allfileextension;
    },
    Allstatusetax() {
      console.log(this.statusetax.length === this.liststatusetax.length);
      return this.statusetax.length === this.liststatusetax.length;
    },
    Somestatusetax() {
      return this.statusetax.length > 0 && !this.Allstatusetax;
    },
    disableByPackage() {      
      return this.dataCheckPackage.type === 'Business'? this.dataCheckPackage.package ? false : true : this.dataCheckPackage.package ? false : true
      // console.log('this.package',this.package);
      // return !(this.package === 'Y')
    },
    disableDataStorageOverload() {
      return this.convertstorageToByte(this.storage_usage_biz) > this.convertstorageToByte(this.dataStorageMax);
    },
    listsize() {
      return [
        { value: 10, disable: false }, 
        { value: 20, disable: this.disableByPackage }, 
        { value: 50, disable: this.disableByPackage }, 
        { value: 100, disable: this.disableByPackage }, 
        { value: 1000, disable: this.disableByPackage },     
      ]
    },
    headers() {
      if (this.dataAccountActive["type"] === "Business") {
        let _items = [{
          text: "#",
          align: "center",
          sortable: false,
          value: "data_type",
          width: "5%",
        }];
        if (this.feature.filename === "Y") {
          _items.push({
            text: "tablefile.filenameonly",
            lign: "left",
            value: "filename",
            sortable: false,
            width: "10%",
          })
        } 
        if (this.feature.document_no === "Y") {
          _items.push({
            text: "tablefile.docno",
            align: "center",
            value: "document_no",
            sortable: false,
            width: "5%",
          })
        } 
        if (this.feature.document_date === "Y") {
          _items.push({
            text: "tablefile.docdate",
            align: "center",
            value: "document_date",
            sortable: false,
            width: "5%",
          })
        } 
        if (this.feature.document_type === "Y") {
          _items.push({
            text: "tablefile.doctype",
            value: "document_type",
            align: "center",
            sortable: false,
            width: "10%",
          })
        } 
        if (this.feature.seller_name === "Y") {
          _items.push({
            text: "tablefile.sendername",
            value: "sender_name",
            align: "center",
            sortable: false,
            width: "10%",
          })
        } 
        if (this.feature.cre_dtm === "Y") {
          _items.push({
            text: "tablefile.inboxdate",
            value: "cre_dtm",
            align: "center",
            sortable: false,
            width: "10%",
          })
        } 
        if (this.feature.status_onechat === "Y") {
          _items.push({
            text: "tablefile.taxrefund",
            value: "tax_refund",
            align: "center",
            sortable: false,
            width: "15%",
          })
        }     
        _items.push({
          text: "tablefile.option",
          value: "file_id",
          align: "center",
          sortable: false,
          width: "5%",
        })
        return _items;
      } else {
        let _items = [{
          text: "#",
          align: "center",
          sortable: false,
          value: "data_type",
          width: "5%",
        },
        {
          text: "tablefile.filenameonly",
          lign: "left",
          value: "filename",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.docno",
          align: "center",
          value: "document_no",
          sortable: false,
          width: "5%",
        },
        {
          text: "tablefile.docdate",
          align: "center",
          value: "document_date",
          sortable: false,
          width: "5%",
        },
        {
          text: "tablefile.doctype",
          value: "document_type",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.sendername",
          value: "sender_name",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.inboxdate",
          value: "cre_dtm",
          align: "center",
          sortable: false,
          width: "10%",
        },
        {
          text: "tablefile.taxrefund",
          value: "tax_refund",
          align: "center",
          sortable: false,
          width: "15%",
        },
        {
          text: "tablefile.option",
          value: "file_id",
          align: "center",
          sortable: false,
          width: "5%",
        }];      
        return _items;
      }
    }
  },
  filters: {
    subStr: function(string) {
      if (string == null || string == "" || string == undefined) {
        return string;
      } else {
        if (string.length > 30) return string.substring(0, 30) + "...";
        else return string;
      }
    },
  },
  watch: {
    sort_by() {
      this.getSourceSystem();
      this.getDataFilters();
    },
    direction() {
      this.getSourceSystem();
      this.getDataFilters();
    }
  },
  methods: {
    async fn_printPDF() {
      this.opensnackbarloadingprint = true;
      // this.loaddataprogress_print = true;
      this.percent = 0;
      let listfile = "";
      let notpdf = false;
      let i;
      console.log("this.filemultipledownload",this.filemultipledownload);
      for (i = 0; i < this.filemultipledownload.length; i++) {
        if (this.filemultipledownload[i]["data_type"] !== "pdf") {
          Toast.fire({
            icon: "error",
            title: "โปรดเลือกเฉพาะไฟล์ PDF",
          });
          notpdf = true;
          this.opensnackbarloadingprint = false;
          // this.loaddataprogress_print = false;
        // } else if (this.filemultipledownload[i]["status_lock_data"] === "Y") {
        //   Toast.fire({
        //     icon: "error",
        //     title: "บางไฟล์ที่คุณเลือกมีการล็อกไว้",
        //   });
        //   notpdf = true;
        //   this.loaddataprogress_print = false;
        } else {
          listfile += "&list_file=" + this.filemultipledownload[i]["file_id"];
          //console.log(listfile);
        }
      }
      if (i === this.filemultipledownload.length && notpdf !== true) {
        let auth = await gbfGenerate.generateToken();

        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_API +
          "/api/merge_pdf?account_id=" +
          this.dataAccountId +
          "&user_id=" +
          this.dataUsername +
          "&Authorization=" +
          auth.code +
          listfile;
        this.axios.get(url, {
              headers: { Authorization: auth.code },
              onDownloadProgress: (progressEvent) => {
                console.log("progressEvent", progressEvent);
                let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                // this.loadingpercent = progresspercent;
                this.percent = progresspercent;
                // console.log("progresspercent",progresspercent);
              }
            }).then((response) => {
          console.log(response.data.status);
          if (response.data.status !== "ER") {
            this.statusmutipledownload = false;
            this.filemultipledownload = [];
            this.opensnackbarloadingprint = false;
            // this.loaddataprogress_print = false;
            window.open(url);
          } else {
            this.opensnackbarloadingprint = false;
            // this.loaddataprogress_print = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถรวมไฟล์เสียได้",
            });
          }
        });
      }
    },
    calcMargin(i) {
      return (i*60) + 'px'
    },
    hide(i){
      this.snackbars.splice(i,1)
    },
    async fn_changestatusetax_multi(_item, status) {
      if(this.filemultipledownload.length > 0){     
        let listfilemulti = []
        for(let i = 0 ; i< this.filemultipledownload.length; i++){
          listfilemulti.push(this.filemultipledownload[i].inbox_id)
        }
        let payload = {
          account_id: this.dataAccountId,
          business_id: this.dataAccountActive.business_info.business_id,
          inbox_id: listfilemulti,
          user_id: this.dataUsername,
          refund_status: status,
        };
        console.log("payload", payload);
        let auth = await gbfGenerate.generateToken();
        await this.axios
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/update_inbox_tax_refund", payload, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            console.log(response);
            if (response.data.status === "OK") {
              this.multistatus_etax = "";
              Toast.fire({
                icon: "success",
                title: "Update Status Success",
              });
              this.getSourceSystem();
            }
          });
      }else{
        this.multistatus_etax = "";
        Toast.fire({
          type: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      }
    },
    async fn_changestatusetax(_item, status) {
      let payload = {
        account_id: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        inbox_id: [_item.inbox_id],
        user_id: this.dataUsername,
        refund_status: status,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/update_inbox_tax_refund", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: "Update Status Success",
            });
            this.getSourceSystem();
          }
        });
    },
    fn_selectall_fileextension() {
      let listex = [];
      this.$nextTick(() => {
        if (this.Allfileextension) {
          this.fileextension = [];
          console.log("fileextension", this.fileextension);
        } else {
          console.log(this.fileextension);
          console.log(this.listExtensionFile);
          for (let i = 0; i < this.listExtensionFile.slice().length; i++) {
            listex.push(this.listExtensionFile[i].data_type);
          }
          this.fileextension = listex;
          // // this.fileextension = this.listExtensionFile.slice()
          // console.log("fileextension else",this.fileextension);
        }
      });
    },
    fn_selectall() {
      let listdocument = [];
      this.$nextTick(() => {
        console.log(this.Alldoctype);
        if (this.Alldoctype) {
          this.documenttype = [];
          console.log("documenttype", this.documenttype);
        } else {
          for (let i = 0; i < this.listDoctype.slice().length; i++) {
            listdocument.push(this.listDoctype[i].document_type);
          }
          this.documenttype = listdocument;
          console.log("documenttype else", this.documenttype);
        }
      });
    },
    fn_selectall_statusetax(){
      this.$nextTick(() => {
          if (this.Allstatusetax) {
            this.statusetax = []
            console.log("statusetax",this.statusetax);
          } else {
            this.statusetax = this.liststatusetax.slice()
            console.log("this.statusetax",this.statusetax);
          }
        })
    },
    fn_gotoresend() {
      if (this.$route.query.biz === "" || this.$route.query.biz === null || this.$route.query.biz === undefined) {
        this.taxid_biz = this.dataAccountActive.taxid;
        this.biz_id = this.dataAccountActive.business_info.business_id;
      } else {
        this.taxid_biz = this.dataBusinessProfile[this.checkallbiz].id_card_num;
        this.biz_id = this.dataBusinessProfile[this.checkallbiz].business_id;
      }
      console.log("taxid_biz ++", this.taxid_biz);
      this.opendialogresend = true;
    },
    // เปิด dialog
    fn_openset() {
      this.opendialogedit = true;
    },
    async deleterequest(_item, status) {
      console.log(_item, status);
      let payload = {
        account_id: this.dataAccountId,
        inbox_id: [_item.inbox_id],
        request_answer: status,
        delete_log: "inbox",
        user_id: this.dataUsername,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("payload", payload);
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_log_inbox_requset", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            if (status === "Y") {
              Toast.fire({
                icon: "success",
                title: "Delete Success",
              });
              this.getSourceSystem();
            } else if (status === "N") {
              Toast.fire({
                icon: "success",
                title: "Success",
              });
              this.getSourceSystem();
            }
          }
        });
    },
    async btnnotapprove(item) {
      let payload = {
        inbox_id: [item.inbox_id],
        status: "not_approved",
        user_id: "",
        account_id: this.dataAccountId,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("payload", payload);
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/set_unsend_inbox", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: "Success",
            });
            this.getSourceSystem();
          }
        });
    },
    async btnunsend(item) {
      let payload = {
        inbox_id: [item.inbox_id],
        status: "unsend",
        user_id: "",
        account_id: this.dataAccountId,
      };
      let auth = await gbfGenerate.generateToken();
      console.log("payload", payload);
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/set_unsend_inbox", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: "Success",
            });
            this.getSourceSystem();
          }
        });
    },
    closeclicksort() {
      this.clickSortorderby = false;
      this.checkpagination = false;
      this.check();
    },
    check() {
      if (this.checkpagination === true) {
        this.checksortorderby();
      } else {
        this.getSourceSystem();
      }
    },
    checksortorderby() {
      let _type = parseInt(this.$route.query.type);
      console.log("route", this.$route.query);

      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (this.$route.query.biz === undefined || this.$route.query.biz === null || this.$route.query.biz === "") {
            this.$router.push("/myinbox-system");
          } else {
            this.getDataExtensionFile(this.$route.query.biz, "2");
            this.getDataDocType(this.$route.query.biz, "2");
            this.sortorderby(this.$route.query.biz, "2");
          }
        } else {
          if (this.$route.query.biz === undefined || this.$route.query.biz === null || this.$route.query.biz === "") {
            this.getDataExtensionFile(this.dataAccountActive.business_info["business_id"], "2");
            this.getDataDocType(this.dataAccountActive.business_info["business_id"], "2");
            this.sortorderby(this.dataAccountActive.business_info["business_id"], "2");
          } else {
            this.getDataExtensionFile(this.$route.query.biz, "2");
            this.getDataDocType(this.$route.query.biz, "2");
            this.sortorderby(this.$route.query.biz, "2");
          }
        }
      } else {
        this.getDataDocType(this.dataAccountId, "1");
        this.sortorderby(this.dataAccountId, "1");
        this.getDataExtensionFile(this.dataAccountId, "1");
      }
    },
    async sortorderby(_accountid, _type) {
      let checkstatus = [];
      console.log("accountidDataInbox", _accountid);
      this.inboxdata = [];
      let listcsv = [];
      let offset = this.size * (this.page - 1);
      let payload = {
        limit: this.size.toString(),
        offset: offset.toString(),
        receiver_id: [_accountid],
        receiver_type: _type,
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDate(this.fromdatedocument),
        doc_date_end: this.formatDate(this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDate(this.fromdateupload),
        cre_dtm_end: this.formatDate(this.todateupload),
        sender_name: this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status: this.sortStatus,
        order_by: "test",
        sort_by: this.sort_by,
        direction: this.direction,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/v3/get_file_inbox", payload, {
          headers: { Authorization: auth.code },
        })
        .then((res) => {
          if (res.data.status === "OK") {
            console.log("res", res);
            // this.package = res.data.package;
            // this.listcsv.push(res.data.result.data)
            //  แก้
            for (let i = 0; i < res.data.result.data.length; i++) {
            //   // if (res.data.result.data[i].relation_status === 0 || res.data.result.data[i].relation_status === 2) {
            //     checkstatus.push(res.data.result.data[i]);
            //   // }
             checkstatus.push(res.data.result.data[i]);
            }
             console.log("---",checkstatus);

            // console.log("res",this.listcsv);
            // this.inboxdata = [];
            if ("total_file" in res["data"]["result"]) {
              this.totalfile = res.data.result["total_file"] || 0;
              this.inboxdata = checkstatus;
              console.log("this.inboxdata", this.inboxdata);

              this.buff = res.data.result["data"];
            } else {
              this.totalfile = 0;
              this.inboxdata = [];
              this.buff = [];
            }
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            this.totalfile = 0;
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          this.totalfile = 0;
          console.log(error);
        });
    },
    // เช็คเวลาต้องการค้นหา status อื่น
    clickstatus(val) {
      let sortStatus = "";
      this.page = 1;
      if (val === "All") {
        this.sortStatus = "";
      } else if (val === "Active") {
        this.sortStatus = "active";
      } else if (val === "Pending") {
        this.sortStatus = "pending";
      } else if (val === "Reject") {
        this.sortStatus = "reject";
      } else if (val === "Request") {
        this.sortStatus = "request";
      } else if (val === "Unsend") {
        this.sortStatus = "unsend";
      } else if (val === "Duplicate") {
        this.sortStatus = "duplicate";
      } else if (val === "request for removal") {
        this.sortStatus = "request_for_removal";
      } else if (val === "resend from etax") {
        this.sortStatus = "resend";
      }
      console.log(this.sortStatus);

      this.getSourceSystem();
    },
    async pendingfile(file) {
      let payload;
      payload = {
        inbox_id: [file.inbox_id],
        status: "pending",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/set_pending_active_inbox", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if ((response.data.status = "OK")) {
            Toast.fire({
              icon: "success",
              title: "OK",
            });
            this.getSourceSystem();
          } else {
          }
        });
    },
    async rejectfile(file) {
      let payload;
      payload = {
        inbox_id: [file.inbox_id],
        status: "reject",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/set_pending_active_inbox", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if ((response.data.status = "OK")) {
            Toast.fire({
              icon: "success",
              title: "OK",
            });
            this.getSourceSystem();
          } else {
          }
        });
    },
    async approvefile(file) {
      let payload;
      payload = {
        inbox_id: [file.inbox_id],
        status: "active",
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/set_pending_active_inbox", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if ((response.data.status = "OK")) {
            Toast.fire({
              icon: "success",
              title: "OK",
            });
            this.getSourceSystem();
          } else {
          }
        });
    },
    fn_confirmdelete(file) {
      this.listdata = file;
      console.log("++", this.listdata);
      this.confirmdelete = true;
    },
    async fn_deletetotrash(file) {
      let payload;
      let filedata = [];
      console.log("file", file);
      filedata.push(file["inbox_id"]);
      payload = {
        inbox_id: filedata,
        account_id: this.dataAccountId,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_inbox", payload, { headers: { Authorization: auth.code } })
        .then((response) => {
          if (response.data.status === "OK") {
            Toast.fire({
              icon: "success",
              title: "OK",
            });
            console.log("delete", response.length, response.data);
            // this.clearmultipledownload();
            this.statusmutipledownload = false;
            this.getSourceSystem();
            this.confirmdelete = false;
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    multipleDelete() {
      console.log("this.filemultipledownload", this.filemultipledownload);
      this.opensdialogmultidelinboxoutbox = true;
      // if (this.filemultipledownload.length < 1) {
      //   Toast.fire({
      //     icon: "warning",
      //     title: "choose file",
      //   });
      // } else {
      //   let payload;
      //   let filedata = [];
      //   for (let i = 0; i < this.filemultipledownload.length; i++) {
      //     filedata.push(this.filemultipledownload[i]["inbox_id"]);
      //   }
      //   payload = {
      //     inbox_id: filedata,
      //     account_id: this.dataAccountId,
      //   };

      //   this.axios
      //     .post(
      //       process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/delete_inbox",
      //       payload
      //     )
      //     .then((response) => {
      //       if (response.data.status === "OK") {
      //         Toast.fire({
      //           icon: "success",
      //           title: "OK",
      //         });
      //         console.log("delete", response.length, response.data);
      //         this.clearmultipledownload();
      //         this.statusmutipledownload = false;
      //         this.getSourceSystem();
      //       } else {
      //         Toast.fire({
      //           icon: "error",
      //           title: response.data.errorMessage,
      //         });
      //       }
      //     });
      // }
    },
    filteredItems(search) {
      this.search = search;
      // console.log(search);
      // console.log("in",this.inboxdata);
    },
    checkSort() {
      // this.loadfolder();
      this.inboxdata = this.buff;
      this.clickSort = false;
      this.multiSort = false;
      // this.multiSortdate=false
      // this.multiSortsize=false
    },
    sortFile(parameter) {
      this.folderSort = [];
      this.fileSort = [];
      this.sortdata = [];
      this.inboxdata = [];
      let i, j;
      for (i = 0; i < parameter.length; i++) {
        if (parameter[i].data_type === "folder") {
          this.folderSort.push(parameter[i]);
        } else this.fileSort.push(parameter[i]);
      }
      if (this.sortby === "name") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.filename.localeCompare(b.filename);
          });
          this.fileSort.sort(function(a, b) {
            return a.filename.localeCompare(b.filename);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.filename.localeCompare(a.filename);
          });
          this.fileSort.sort(function(a, b) {
            return b.filename.localeCompare(a.filename);
          });
        }
      } else if (this.sortby === "inboxdate") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.cre_dtm.localeCompare(b.cre_dtm);
          });
          this.fileSort.sort(function(a, b) {
            return a.cre_dtm.localeCompare(b.cre_dtm);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.cre_dtm.localeCompare(a.cre_dtm);
          });
          this.fileSort.sort(function(a, b) {
            return b.cre_dtm.localeCompare(a.cre_dtm);
          });
        }
      } else if (this.sortby === "docdate") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.document_date.localeCompare(b.document_date);
          });
          this.fileSort.sort(function(a, b) {
            return a.document_date.localeCompare(b.document_date);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.document_date.localeCompare(a.document_date);
          });
          this.fileSort.sort(function(a, b) {
            return b.document_date.localeCompare(a.document_date);
          });
        }
      } else if (this.sortby === "docno") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.document_no.localeCompare(b.document_no);
          });
          this.fileSort.sort(function(a, b) {
            return a.document_no.localeCompare(b.document_no);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.document_no.localeCompare(a.document_no);
          });
          this.fileSort.sort(function(a, b) {
            return b.document_no.localeCompare(a.document_no);
          });
        }
      } else if (this.sortby === "doctype") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.document_type.localeCompare(b.document_type);
          });
          this.fileSort.sort(function(a, b) {
            return a.document_type.localeCompare(b.document_type);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.document_type.localeCompare(a.document_type);
          });
          this.fileSort.sort(function(a, b) {
            return b.document_type.localeCompare(a.document_type);
          });
        }
      } else if (this.sortby === "sendname") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.sender_name.localeCompare(b.sender_name);
          });
          this.fileSort.sort(function(a, b) {
            return a.sender_name.localeCompare(b.sender_name);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.sender_name.localeCompare(a.sender_name);
          });
          this.fileSort.sort(function(a, b) {
            return b.sender_name.localeCompare(a.sender_name);
          });
        }
      } else if (this.sortby === "receivername") {
        if (this.multiSort === true) {
          this.folderSort.sort(function(a, b) {
            return a.receiver_name.localeCompare(b.receiver_name);
          });
          this.fileSort.sort(function(a, b) {
            return a.receiver_name.localeCompare(b.receiver_name);
          });
        } else {
          this.folderSort.sort(function(a, b) {
            return b.receiver_name.localeCompare(a.receiver_name);
          });
          this.fileSort.sort(function(a, b) {
            return b.receiver_name.localeCompare(a.receiver_name);
          });
        }
      }

      this.sortdata.push(this.folderSort);
      this.sortdata.push(this.fileSort);
      for (i = 0; i < this.sortdata.length; i++) {
        for (j = 0; j < this.sortdata[i].length; j++) {
          this.inboxdata.push(this.sortdata[i][j]);
        }
      }
      return this.inboxdata;
    },
    clearmultipledownload() {
      this.filemultipledownload.splice(0, this.filemultipledownload.length);
    },
    zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
    async multipledownload() {
      console.log("filemultipledownload",this.filemultipledownload);
      this.percentCompleted = 0;
      this.percent = 0;
      var d = new Date();
      this.datetime =
        d.toISOString().slice(0, 10) +
        " " +
        "at" +
        " " +
        this.zeroPadding(d.getHours(), 2) +
        "-" +
        this.zeroPadding(d.getMinutes(), 2) +
        "-" +
        this.zeroPadding(d.getSeconds(), 2);
      if (this.filemultipledownload.length < 1) {
        Toast.fire({
          type: "warning",
          title: this.$t("myinboxPage.msg.nofile"),
        });
      } else {
        // this.$store.dispatch("show_loading", true);
        this.snackbars=[];
        let filedata = [];
        for (let i = 0; i < this.filemultipledownload.length; i++) {
          filedata.push(this.filemultipledownload[i]["inbox_id"]);
        console.log("++",this.filemultipledownload[i]);
        }
        console.log(filedata);

        let payload = {
          // list_file: filedata,
          account_id: this.dataAccountId,
          data_type:"inbox",
          inbox_id:filedata
        };


        this.btsdownload = true;
        this.opensnackbarmultidownload = true;
        let auth = await gbfGenerate.generateToken();
        let config = {headers: { Authorization: auth.code },
          // onDownloadProgress: (progressEvent) => {
          //   let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          //   // console.log(progresspercent);
          //   if (progresspercent === 95) {
          //     this.percentCompleted = 95;
          //   } else {
          //     this.percentCompleted = progresspercent;
          //   }
          //   this.percent = this.percentCompleted;
          // },
          // withCredentials: false,
          // responseType: "arraybuffer",
        };
        await this.axios
          // .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/multiple_downloads_file", payload, config)
          .post(process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE + "/api/prepare_zip_file_inbox", payload, config)
          .then((response) => {
            this.statusmutipledownload = false;

            console.log(response);
            if (response.statusText === "OK") {
              console.log("response OK",response);
              this.fn_loop_multidownloading(response.data.all_file, response.data.zip_name, response.data.url);
              // if (
              //   parseInt(response.headers["content-length"]) < 700 &&
              //   parseInt(response.headers["content-type"]) === "application/json"
              // ) {
              //   console.log("response < 1000", response);
              //   this.opensnackbarmultidownload = false;
              //   let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              //   console.log("responseerror", res_error);
              //   Toast.fire({
              //     icon: "error",
              //     title: res_error.errorMessage,
              //   });
              // } else {
              //   this.percentCompleted = 100;
              //   this.btsdownload = false;
              //   let headers = response.headers;
              //   let blob = new Blob([response.data], { type: headers["content-type"] });
              //   let link = document.createElement("a");
              //   link.href = window.URL.createObjectURL(blob);
              //   link.download = this.InboxFile + "_" + this.datetime;
              //   link.click();
              //   // const content = response.headers["content-type"];
              //   // const blob = new Blob([response.data], {type: content});
              //   // saveAs(blob, this.InboxFile + "_" + this.datetime + ".zip");
              //   setTimeout(() => {
              //     this.opensnackbarmultidownload = false;
              //   }, 2500);
              // }
            } else {
              this.opensnackbarmultidownload = false;
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
            //     const url = window.URL.createObjectURL(new Blob([response.data]));
            //     const link = document.createElement("a");
            //     link.href = url;
            //     link.setAttribute("download",datetime+".zip");
            //     document.body.appendChild(link);
            //     link.click();

            // this.$store.dispatch("show_loading", false);
            this.clearmultipledownload();
          })
          .catch((error) => {
            this.btsdownload = false;
            this.opensnackbarmultidownload = false;
            // this.$store.dispatch("show_loading", false);
            Toast.fire({
              icon: "error",
              title: this.$t("myinboxPage.msg.apierror"),
            });
            console.log(error);
          });
      }
    },
    // multipledownload() {
    //   var d = new Date();
    //   let datetime =d.toISOString().slice(0,10)+" "+"at"+" "+this.zeroPadding(d.getHours(),2)+"-"+this.zeroPadding(d.getMinutes(),2)+"-"+this.zeroPadding(d.getSeconds(),2);
    //   if (this.filemultipledownload.length < 1) {
    //     Toast.fire({
    //       type: "warning",
    //       title: this.$t("myinboxPage.msg.nofile")
    //     });
    //   } else {
    //     this.$store.dispatch("show_loading", true);
    //     let filedata = [];
    //     for (let i = 0; i < this.filemultipledownload.length; i++) {
    //       filedata.push(this.filemultipledownload[i]["file_id"]);
    //     }

    //     let payload = {
    //       list_file: filedata,
    //       account_id: this.dataAccountId
    //     };

    //     this.axios
    //       .post(
    //         process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
    //           "/api/multiple_downloads_file",
    //         payload,
    //         {
    //           withCredentials: false,
    //           responseType: "blob"
    //         }
    //       )
    //       .then(response => {
    //         this.statusmutipledownload = false;
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.setAttribute("download", "InboxFile"+" "+datetime+".zip");
    //         document.body.appendChild(link);
    //         link.click();
    //         this.$store.dispatch("show_loading", false);
    //         this.clearmultipledownload();
    //       })
    //       .catch(error => {
    //         this.$store.dispatch("show_loading", false);
    //         Toast.fire({
    //           icon: "error",
    //           title: this.$t("myinboxPage.msg.apierror")
    //         });
    //         console.log(error);
    //       });
    //   }
    // },
    clickpagination() {
      if (this.checkpagination === true) {
        this.checksortorderby();
      } else {
        this.getSourceSystem();
      }
    },
    // downloadfile(currentfile) {
    //   if (currentfile.file_type === "folder") {
    //     Toast.fire({
    //       type: "warning",
    //       title: "Coming Soon !!"
    //     });
    //   } else {
    //     let url =
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
    //       "/api/v2/download_file?user_id=" +
    //       this.dataUsername +
    //       "&file_id=" +
    //       currentfile.file_id;
    //     window.location.assign(url);
    //   }
    // },
    // fn_calculate_size(_sizefilebyte) {
    //   console.log("ขนาดไฟล์เป็น GB",(_sizefilebyte / 1073741824).toFixed(2));
    //    return parseInt((_sizefilebyte / 1073741824).toFixed(2))
    //   // return gb_sizeDataConverter.convertStorageToKbMbGb(_sizefilebyte);
    // },
    downloadfile(currentfile) {
      console.log(currentfile);
      // let checkfilesize = this.fn_calculate_size(currentfile.file_size)

      this.percentCompleted = 0;
      this.percent = 0;
      if (currentfile.file_type === "folder") {
        Toast.fire({
          icon: "warning",
          title: "Coming Soon !!",
        });
        //   let url =
        //     process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
        //     "/api/v2/download_folder?account_id=" +
        //     this.dataAccountId +
        //     "&folder_id=" +
        //     currentfile.file_id;
        //   this.snackbardowloadonefile = true;
        // this.axios
        //       .get(url,{
        //         onDownloadProgress: (progressEvent) => {
        //           this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //           console.log(this.percentCompleted);
        //           this.namedowload = currentfile.file_name;
        //           if(this.percentCompleted = 95){
        //             this.percentCompleted = 95;
        //           }

        //         },
        //         withCredentials: false,
        //         responseType: "arraybuffer"
        //       })
        //       .then(response => {
        //         if(response.statusText === "OK"){
        //           this.percentCompleted = 100;
        //         }
        //         // console.log("fff",response);
        //         // this.namedowload = currentfile.file_name;
        //         const blob = new Blob([response.data]);
        //         const content = response.headers['content-type'];
        //         saveAs(blob,currentfile.file_name+ ".zip")

        //       })
        //       .catch(error => {
        //         Toast.fire({
        //           icon: "error",
        //           title: "ไม่สามารถดาวน์โหลด " + currentfile.file_name + " นี้ได้"
        //         });
        //         console.log(error);
        //       });
      } else {
        this.btsdownload = true;
        let payload = {
          account_id: this.dataAccountId,
          user_id: this.dataUsername,
          file_id: currentfile.file_id,
        };
        let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
          "/api/v2/download_file?user_id=" +
          this.dataUsername +
          "&file_id=" +
          currentfile.file_id;
        // this.snackbardowloadonefile = true;
        // if(checkfilesize < 1.00){
        this.opensnackbarmultidownload = true;
        this.namedowload = currentfile.filename;
        this.axios
          .get(url, {
            onDownloadProgress: (progressEvent) => {
              let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              // console.log(progresspercent);
              if (progresspercent === 95) {
                this.percentCompleted = 95;
              } else {
                this.percentCompleted = progresspercent;
              }
              this.percent = this.percentCompleted;
            },
            withCredentials: false,
            responseType: "arraybuffer",
          })
          .then((response) => {
            if (response.statusText === "OK") {
              console.log("response++++++++++++ OK", response);
              if (
                parseInt(response.headers["content-length"]) < 700 &&
                parseInt(response.headers["content-type"]) === "application/json"
              ) {
                console.log("response < 1000", response);
                this.opensnackbarmultidownload = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                console.log("responseerror", res_error);
                Toast.fire({
                  icon: "error",
                  title: res_error.errorMessage,
                });
              } else {
                this.percentCompleted = 100;
                this.btsdownload = false;

                let headers = response.headers;
                let blob = new Blob([response.data], { type: headers["content-type"] });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = currentfile.filename;
                link.click();
                // const blob = new Blob([response.data]);
                // const content = response.headers["content-type"];
                // saveAs(blob, currentfile.filename);
                setTimeout(() => {
                  this.opensnackbarmultidownload = false;
                }, 2500);
              }
            } else {
              this.opensnackbarmultidownload = false;
            }
          })
          .catch((error) => {
            this.btsdownload = false;
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถดาวน์โหลด " + currentfile.filename + " นี้ได้",
            });
            console.log(error);
          });
        // }else{
        //   window.location.assign(url)
        //     this.btsdownload = false;
        //     this.opensnackbarmultidownload = false;
        // }
      }
    },
    previewfile(file) {
      console.log("======",file);
      if(file.status !== 'pending'){
        this.currentfile = file;
        this.openpreviewfile = true;
      }
    },
    loadstorage() {
      this.$store.dispatch("check_storage", this.dataAccountId).then((res) => {});
    },
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      this.todateupload = format(new Date(), "yyyy-MM-dd");
    },
    changeFromdatedocument() {
      this.$refs.dialogFromdatedocument.save(this.fromdatedocument);
      this.todatedocument = format(new Date(), "yyyy-MM-dd");
    },
    getSourceSystem() {
      let _type = parseInt(this.$route.query.type);
      console.log("route", this.$route.query);

      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (this.$route.query.biz === undefined || this.$route.query.biz === null || this.$route.query.biz === "") {
            this.$router.push("/myinbox-system");
          } else {
            this.getDataExtensionFile(this.$route.query.biz, "2");
            this.getDataDocType(this.$route.query.biz, "2");
            this.getDataInbox(this.$route.query.biz, "2");
          }
        } else {
          if (this.$route.query.biz === undefined || this.$route.query.biz === null || this.$route.query.biz === "") {
            this.getDataExtensionFile(this.dataAccountActive.business_info["business_id"], "2");
            this.getDataDocType(this.dataAccountActive.business_info["business_id"], "2");
            this.getDataInbox(this.dataAccountActive.business_info["business_id"], "2");
          } else {
            this.getDataExtensionFile(this.$route.query.biz, "2");
            this.getDataDocType(this.$route.query.biz, "2");
            this.getDataInbox(this.$route.query.biz, "2");
          }
        }
      } else {
        this.getDataDocType(this.dataAccountId, "1");
        this.getDataInbox(this.dataAccountId, "1");
        this.getDataExtensionFile(this.dataAccountId, "1");
        // if (this.dataAccountActive["type"] === "Business") {
        //   this.loadSourceSystem(_accountid, _type)
        // } else {
        //   this.loadSourceSystem(_accountid, _type)
        // }
      }
    },
    async getDataInbox(_accountid, _type) {
      // เชคค่า status ให้ค่าเริ่มต้นเป็น active
      this.loaddataprogress = true;
      this.totalfile = 0;
      if (this.statusde == "Active") {
        this.sortStatus = "active";
      }
      let checkstatus = [];
      console.log("accountidDataInbox", _accountid);
      this.inboxdata = [];
      let listcsv = [];
      let offset = this.size * (this.page - 1);
      let payload = {
        limit: this.size.toString(),
        offset: offset.toString(),
        receiver_id: [_accountid],
        receiver_type: _type,
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDate(this.fromdatedocument),
        doc_date_end: this.formatDate(this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDate(this.fromdateupload),
        cre_dtm_end: this.formatDate(this.todateupload),
        sender_name: this.sendername,
        system: this.$route.query.systemid,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status: this.sortStatus,
        refund_status: this.statusetax == "Y" ? "Y" : this.statusetax == "N" ? "N" : "",
        sort_by: this.sort_by,
        direction: this.direction,
        inbox_page: this.dataAccountActive.type === 'Citizen' ? "1" : "2"
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/v3/get_file_inbox", payload, {
          headers: { Authorization: auth.code },
        })
        .then((res) => {
          if (res.data.status === "OK") {
            console.log("res", res);
            // this.package = res.data.package;
            // this.listcsv.push(res.data.result.data)
            //  แก้
            for (let i = 0; i < res.data.result.data.length; i++) {
            //   // if (res.data.result.data[i].relation_status === 0 || res.data.result.data[i].relation_status === 2) {
            //     //// let data={};
            //     //// data["file_icon"] = this.seticon(res.data.result.data[i]["data_type"]);
            //     checkstatus.push(res.data.result.data[i]);
            //   // }
            checkstatus.push(res.data.result.data[i]);
            }
            console.log("---",checkstatus);

            // console.log("res",this.listcsv);
            // this.inboxdata = [];
            if ("total_file" in res["data"]["result"]) {
              this.totalfile = res.data.result["total_file"] || 0;
              this.inboxdata = checkstatus;
              console.log("this.inboxdata", this.inboxdata);

              this.buff = res.data.result["data"];
              console.log("bbbbb", this.buff);
              this.status_etax = this.buff.tax_refund;
              console.log("aaaaaaa", this.status_etax);
            } else {
              this.totalfile = 0;
              this.inboxdata = [];
              this.buff = [];
            }
            this.loaddataprogress = false;
          } else {
            this.loaddataprogress = false;
            this.totalfile = 0;
          }
        })
        .catch((error) => {
          this.loaddataprogress = false;
          this.totalfile = 0;
          console.log(error);
        });
    },
    seticon(parameter) {
      // console.log(parameter);
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video-outline", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link-outline", "pink"];
      } else {
        dataicon = ["mdi-file-outline", "black"];
      }

      return dataicon;
    },
    seticon_new(parameter) {
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel", "green"];
      } else if (parameter === "docx" || parameter === "doc") {
        dataicon = ["mdi-file-word", "primary"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text", "blue-grey"];
      } else if (parameter === "jpeg" || parameter === "jpg" || parameter === "png") {
        dataicon = ["mdi-file-image", "blue-grey"];
      } else if (
        parameter === "avi" ||
        parameter === "mpeg" ||
        parameter === "mov" ||
        parameter === "mp4" ||
        parameter === "mkv" ||
        parameter === "wmv" ||
        parameter === "3gp" ||
        parameter === "flv"
      ) {
        dataicon = ["mdi-file-video", "purple"];
      } else if (parameter === "shortcut") {
        dataicon = ["mdi-file-link", "pink"];
      } else {
        dataicon = ["mdi-file", "black"];
      }

      return dataicon;
    },
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${year}${month}${day}`;
    },
    formatdatetime(_datetime) {
      if (_datetime === "" || _datetime === null || _datetime === undefined) {
        return "-";
      } else {
        let dateyear = _datetime.split("")[0] + _datetime.split("")[1] + _datetime.split("")[2] + _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return dateday + "/" + datemonth + "/" + dateyear + " " + hour + ":" + minute + ":" + second;
      }
    },
    setformatdate(_date) {
      if (_date === "" || _date === null || _date === undefined) {
        return "-";
      } else {
        let data = _date.substring(8, 6) + "/" + _date.substring(6, 4) + "/" + _date.substring(0, 4);
        return data || "-";
      }
    },
    getDataFilters() {
      let _type = parseInt(this.$route.query.type);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          // this.$router.push("/myinbox-system");
        } else {
          this.getDataExtensionFile(this.dataAccountActive.business_info["business_id"], "2");
          this.getDataDocType(this.dataAccountActive.business_info["business_id"], "2");
        }
      } else {
        this.getDataDocType(this.dataAccountId, "1");
        this.getDataExtensionFile(this.dataAccountId, "1");
        // if (this.dataAccountActive["type"] === "Business") {
        //   this.loadSourceSystem(_accountid, _type)
        // } else {
        //   this.loadSourceSystem(_accountid, _type)
        // }
      }
    },
     Clear_data(){
      this.filename = '',
      this.fileextension = [],
      this.fromdateupload = '',
      this.todateupload = '',
      this.sendername = '',
      this.receivername = '',
      this.documenttype = [],
      this.statusetax = [],
      this.documentid = '',
      this.documentidto = '',
      this.fromdatedocument = '',
      this.todatedocument = '',
      this.sellertaxid = '',
      this.sellerbranchid = '',
      this.buyertaxid = '',
      this.buyerbranchid = ''
    },
    searchdocument() {
      this.panel = false;
      this.page = 1;
      this.getSourceSystem();
    },
    async getDataDocType(_accountid, _type) {
      this.inboxdata = [];
      let payload = {
        receiver_id: _accountid,
        receiver_type: _type,
        system: this.$route.query.systemid || "",
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/get_all_doctype", payload, { headers: { Authorization: auth.code } })
        .then((res) => {
          if (res.data.status === "OK") {
            this.listDoctype = res.data.result;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getDataExtensionFile(_accountid, _type) {
      this.inboxdata = [];
      let payload = {
        receiver_id: _accountid,
        receiver_type: _type,
        system: this.$route.query.systemid || "",
      };
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/get_all_fileextension", payload, {
          headers: { Authorization: auth.code },
        })
        .then((res) => {
          if (res.data.status === "OK") {
            this.listExtensionFile = res.data.result;
            console.log("get extension", this.listExtensionFile);
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },


    exportfile() {
      let _type = parseInt(this.$route.query.type);
      console.log("data", this.$route.query);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (this.$route.query.biz === undefined || this.$route.query.biz === null || this.$route.query.biz === "") {
          } else {
            this.callexportfile(this.$route.query.biz, "2");
          }
        } else {
          if (this.$route.query.biz === undefined || this.$route.query.biz === null || this.$route.query.biz === "") {
            this.callexportfile(this.dataAccountActive.business_info["business_id"], "2");
          } else {
            this.callexportfile(this.$route.query.biz, "2");
          }
        }
      } else {
        this.callexportfile(this.dataAccountId, "1");
      }
    },
    async callexportfile(_accountid, _type) {
      this.percentCompleted = 0;
      this.count_loadingfile = 0;
      this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
      let payload = {
        receiver_id: [_accountid],
        receiver_type: _type,
        filename: this.filename,
        fileextension: this.fileextension,
        doc_no_start: this.documentid,
        doc_no_end: this.documentidto,
        doc_date_start: this.formatDate(this.fromdatedocument),
        doc_date_end: this.formatDate(this.todatedocument),
        doc_type: this.documenttype,
        cre_dtm_start: this.formatDate(this.fromdateupload),
        cre_dtm_end: this.formatDate(this.todateupload),
        system: this.$route.query.systemid,
        sender_name: this.sendername,
        sellerTaxid: this.sellertaxid,
        sellerBranchid: this.sellerbranchid,
        receiver_name: this.receivername,
        receiverTaxid: this.buyertaxid,
        receiverBranchid: this.buyerbranchid,
        status: this.sortStatus,
        account_id: this.dataAccountId,
        type_downloads : this.typedownloads == true ? 'Y' : 'N',
        type_document : this.typeitem == true ? 'Y' : 'N'

      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code },
        // onDownloadProgress: (progressEvent) => {
        //   let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        //   // console.log(progresspercent);
        //   if (progresspercent === 95) {
        //     this.percentCompleted = 95;
        //   } else {
        //     this.percentCompleted = progresspercent;
        //   }
        // },
        // withCredentials: false,
        // responseType: "arraybuffer",
      };
      await this.axios
        // .post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/v2/downloads_file_byfilter", payload, config)
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/prepare_zip_file_inbox_by_filter", payload, config)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response);     
            this.fn_loop_multidownloading(response.data.all_file, response.data.zip_name, response.data.url);
            this.count_allfile = response.data.total;        
          // if (response.statusText === "OK") {
          //   console.log("response", response);
          //   if (
          //     parseInt(response.headers["content-length"]) < 700 &&
          //     parseInt(response.headers["content-type"]) === "application/json"
          //   ) {
          //     console.log("response < 1000", response);
          //     this.snackbardowloadonetotal = false;
          //     let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
          //     console.log("responseerror", res_error);
          //     Toast.fire({
          //       icon: "error",
          //       title: res_error.errorMessage,
          //     });
          //   } else {
          //     this.percentCompleted = 100;
          //     let headers = response.headers;
          //     let blob = new Blob([response.data], { type: headers["content-type"] });
          //     let link = document.createElement("a");
          //     link.href = window.URL.createObjectURL(blob);
          //     link.download = this.ExportInboxFiles;
          //     link.click();
          //     setTimeout(() => {
          //       this.snackbardowloadonetotal = false;
          //     }, 6000);
          //   }
          } else {
            // this.snackbardowloadonetotal = false;
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
          // const content = response.headers["content-type"];
          // const blob = new Blob([response.data], {type: content});
          // saveAs(blob, this.ExportInboxFiles + ".zip");
        })
        .catch((error) => {
          // this.snackbardowloadonetotal = false;
          this.opensnackbarmultidownload = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportInboxFiles + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading(all_file,zip_name,url){
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url",url);
      
       let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังดาวน์โหลดไฟล์";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.InboxFile + "_" + this.datetime;
          console.log("this.percent",this.percent);
          console.log(this.percentCompleted);
          
          if(res.status !== "OK" ){
            this.snackbar_download_error = true;
            this.filename_error = res.filename
            this.errorMessage = res.errorMessage
            this.snackbars.push({
              filename:this.filename_error ,
              error:this.errorMessage
            })
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            console.log("เข้า");
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new(file_id, location_file, zip_name, all_file, url){
      let url_ =
        url + "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

         console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage:response.data.errorMessage,
              filename:response.data.filename
            });
          }, 1);
        });
      });
    },
    download_finish(file_id,location_file,zip_name,path,url){
      let zip = url.split('zip_id=');
      let zipid = zip[1]
      console.log("url",zip);
      let payload = {
        account_id:this.dataAccountId,
        zip_id: zipid,
        name:this.namefile,
        path:path,
        type_document : this.typeitem == true ? 'Y' : 'N'
      }
      console.log("payload",payload);
      return new Promise((resolve, reject) => {
        this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_file_inbox_finish", payload,{
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.statusloading = "กำลังรวมไฟล์";
          // console.log("testtt", response.data.status);
           if (response.statusText === "OK") {
                console.log("response++++++++++++ OK", response);
                // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
                if (
                  parseInt(response.headers["content-length"]) < 700 &&
                  parseInt(response.headers["content-type"]) === "application/json"
                ) {
                  console.log("response < 1000", response);
                  this.opensnackbarmultidownload = false;
                  this.snackbardowloadonetotal = false;
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                  console.log("responseerror", res_error);
                  Toast.fire({
                    icon: "error",
                    title: res_error.errorMessage,
                  });
                } else {
                  this.percent= 100;
                  this.percentCompleted = 100;
                  this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
                  this.btsdownload = false;
                  let headers = response.headers;
                  let blob = new Blob([response.data], { type: headers["content-type"] });
                  let link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  // link.download = this.InboxFile + "_" + this.datetime;
                  link.download = format(new Date(), "dd_MM_yyyy") + '_InboxFiles';
                  link.click();
                  setTimeout(() => {
                    this.opensnackbarmultidownload = false;
                    this.snackbardowloadonetotal = false;
                  }, 2500);
                }
              }
              // this.snackbars=[];
            this.typedownloads = false;
            this.typeitem = false;

        });
      });
    },


    exportfilecsv() {
      let _type = parseInt(this.$route.query.type);
      console.log("data", this.$route.query);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (this.$route.query.biz === undefined || this.$route.query.biz === null || this.$route.query.biz === "") {
          } else {
            this.callexportfilecsv(this.$route.query.biz, "2");
          }
        } else {
          if (this.$route.query.biz === undefined || this.$route.query.biz === null || this.$route.query.biz === "") {
            this.callexportfilecsv(this.dataAccountActive.business_info["business_id"], "2");
          } else {
            this.callexportfilecsv(this.$route.query.biz, "2");
          }
        }
      } else {
        this.callexportfilecsv(this.dataAccountId, "1");
      }
    },
    // async callexportfilecsv_new(businessid, _type) {
    //   this.percentCompleted = 0;
    //   let payload = {
    //     receiver_id: [businessid],
    //     receiver_type: _type,
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     system: this.$route.query.systemid,
    //     sender_name: this.sendername,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     status: this.sortStatus,
    //     download: "csv",
    //     index: this.listheader,
    //   };
    //   console.log("payload", payload);
    //   console.log("payloadinbox", payload);
    //   this.snackbardowloadonetotalcsv = true;
    //   this.timeout = -1;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code },
    //     onDownloadProgress: (progressEvent) => {
    //       let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //       // console.log(progresspercent);
    //       if (progresspercent === 95) {
    //         this.percentCompleted = 95;
    //       } else {
    //         this.percentCompleted = progresspercent;
    //       }
    //     },
    //     withCredentials: false,
    //     responseType: "arraybuffer",
    //   };
    //   await this.axios
    //     .post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_excel_file_inbox_byfilter_costom_index", payload, config)
    //     .then((response) => {
    //       if (response.statusText === "OK") {
    //         if (
    //           parseInt(response.headers["content-length"]) < 700 &&
    //           parseInt(response.headers["content-type"]) === "application/json"
    //         ) {
    //           console.log("response < 700", response);
    //           this.snackbardowloadonetotalcsv = false;
    //           let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
    //           console.log("responseerror", res_error);
    //           Toast.fire({
    //             icon: "error",
    //             title: res_error.errorMessage,
    //           });
    //         } else {
    //           this.percentCompleted = 100;
    //           let headers = response.headers;
    //           let blob = new Blob([response.data], { type: headers["content-type"] });
    //           let link = document.createElement("a");
    //           link.href = window.URL.createObjectURL(blob);
    //           link.download = this.ExportInboxFilescsv;
    //           link.click();
    //           // const content = response.headers["content-type"];
    //           // const blob = new Blob([response.data], {type: content});
    //           // saveAs(blob, this.ExportInboxFilescsv + ".zip");
    //           setTimeout(() => {
    //             this.snackbardowloadonetotalcsv = false;
    //           }, 1000);
    //         }
    //       } else {
    //         this.snackbardowloadonetotalcsv = false;
    //       }
    //     })
    //     .catch((error) => {
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportInboxFilescsv + " นี้ได้",
    //       });
    //       console.log(error);
    //     });
    // },
    // async callexportfilecsv(businessid, _type) {
    //    this.percentCompleted = 0;
    //     let payload = {
    //       account_id: this.dataAccountId,
    //       receiver_id: [businessid],
    //       receiver_type: _type,
    //       filename: this.filename,
    //       fileextension: this.fileextension,
    //       doc_no_start: this.documentid,
    //       doc_no_end: this.documentidto,
    //       doc_date_start: this.formatDate(this.fromdatedocument),
    //       doc_date_end: this.formatDate(this.todatedocument),
    //       doc_type: this.documenttype,
    //       cre_dtm_start: this.formatDate(this.fromdateupload),
    //       cre_dtm_end: this.formatDate(this.todateupload),
    //       system: this.$route.query.systemid,
    //       sender_name: this.sendername,
    //       sellerTaxid: this.sellertaxid,
    //       sellerBranchid: this.sellerbranchid,
    //       receiver_name: this.receivername,
    //       receiverTaxid: this.buyertaxid,
    //       receiverBranchid: this.buyerbranchid,
    //       status:this.sortStatus
    //     };
    //     // this.snackbardowloadonetotal = true;
    //     console.log("payloadinbox", payload);
    //     this.snackbardowloadonetotalcsv = true;
    //     this.timeout = -1;
    //     let auth = await gbfGenerate.generateToken();
    //     let config = {
    //       headers: { Authorization: auth.code},
    //       onDownloadProgress: (progressEvent) => {
    //         let progresspercent = Math.round(
    //               (progressEvent.loaded * 100) / progressEvent.total
    //         );
    //             // console.log(progresspercent);
    //         if (progresspercent === 95) {
    //           this.percentCompleted = 95;
    //         } else {
    //           this.percentCompleted = progresspercent;
    //         }
    //       },
    //       withCredentials: false,
    //       responseType: "arraybuffer",
    //     }
    //     await this.axios
    //       .post(
    //         process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +
    //           "/api/download_csv_file_inbox_byfilter",
    //         payload,config
    //       )
    //       .then((response) => {
    //         if (response.statusText === "OK") {
    //            if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //               console.log("response < 700", response);
    //               this.snackbardowloadonetotalcsv = false;
    //               let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //                 response.data
    //               );
    //               console.log("responseerror", res_error);
    //               Toast.fire({
    //                 icon: "error",
    //                 title: res_error.errorMessage,
    //               });
    //             } else {
    //               this.percentCompleted = 100;
    //               let headers = response.headers;
    //               let blob = new Blob([response.data],{type:headers['content-type']});
    //               let link = document.createElement('a');
    //               link.href = window.URL.createObjectURL(blob);
    //               link.download = this.ExportInboxFilescsv;
    //               link.click();
    //               // const content = response.headers["content-type"];
    //               // const blob = new Blob([response.data], {type: content});
    //               // saveAs(blob, this.ExportInboxFilescsv + ".zip");
    //               setTimeout(() => {
    //                   this.snackbardowloadonetotalcsv = false;
    //               }, 1000);
    //             }
    //         }else{
    //           this.snackbardowloadonetotalcsv = false;
    //         }
    //       })
    //       .catch((error) => {
    //         Toast.fire({
    //           icon: "error",
    //           title: "ไม่สามารถดาวน์โหลด " + this.ExportInboxFilescsv + " นี้ได้",
    //         });
    //         console.log(error);
    //       });
    // },
    async callexportfilecsv(businessid, _type) {
     this.percentCompleted = 0;
     this.count_loadingfile = 0;
     this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
       let payload = {
          // account_id: this.dataAccountId,
          receiver_id: [businessid],
          receiver_type: _type,
          filename: this.filename,
          fileextension: this.fileextension,
          doc_no_start: this.documentid,
          doc_no_end: this.documentidto,
          doc_date_start: this.formatDate(this.fromdatedocument),
          doc_date_end: this.formatDate(this.todatedocument),
          doc_type: this.documenttype,
          cre_dtm_start: this.formatDate(this.fromdateupload),
          cre_dtm_end: this.formatDate(this.todateupload),
          system: this.$route.query.systemid,
          sender_name: this.sendername,
          sellerTaxid: this.sellertaxid,
          sellerBranchid: this.sellerbranchid,
          receiver_name: this.receivername,
          receiverTaxid: this.buyertaxid,
          receiverBranchid: this.buyerbranchid,
          status:this.sortStatus,
          type_downloads : this.typedownloads == true ? 'Y' : 'N',
          type_document : this.typeitem == true ? 'Y' : 'N',
          sort_by: this.sort_by,
          direction: this.direction,
          refund_status: this.statusetax == "Y" ? "Y" : this.statusetax == "N" ? "N" : "",

      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},
       
        }
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/prepare/download_csv_file_inbox_byfilter", payload, config)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response);
            this.fn_loop_multidownloading_csv(response.data.all_file, response.data.zip_name, response.data.url);  
            this.count_allfile = response.data.total;
          } else {
            // this.snackbardowloadonetotal = false;
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportInboxFiles + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading_csv(all_file,zip_name,url){
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url",url);

       let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังสร้างไฟล์ CSV";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new_csv(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.InboxFile + "_" + this.datetime;
          console.log("this.percent",this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;
   
          // }
           if(res.status !== "OK" ){
             console.log("res",res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename
            this.errorMessage = res.errorMessage
            this.snackbars.push({
              filename:this.filename_error ,
              error:this.errorMessage
            })
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish_csv(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new_csv(file_id, location_file, zip_name, all_file, url){
      let url_ =
        url + "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

         console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage:response.data.errorMessage,
              filename:response.data.filename
            });
          }, 1);
        });
      });
    },
    download_finish_csv(file_id,location_file,zip_name,path,url){
      let zip = url.split('zip_id=');
      let zipid = zip[1]
      console.log("url",zip);
      let payload = {
        account_id:this.dataAccountId,
        zip_id: zipid,
        name:this.namefile,
        path:path,
        type_document : this.typeitem == true ? 'Y' : 'N'
      }
      console.log("payload",payload);
      return new Promise((resolve, reject) => {
        this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_inbox_csv_finish", payload,{
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
          this.statusloading = "กำลังรวมไฟล์";
          // console.log("testtt", response.data.status);
           if (response.statusText === "OK") {
                console.log("response++++++++++++ OK", response);
                // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
                if (
                  parseInt(response.headers["content-length"]) < 700 &&
                  parseInt(response.headers["content-type"]) === "application/json"
                ) {
                  console.log("response < 1000", response);
                  this.opensnackbarmultidownload = false;
                  // this.snackbardowloadonetotal = false;
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                  console.log("responseerror", res_error);
                  Toast.fire({
                    icon: "error",
                    title: res_error.errorMessage,
                  });
                } else {
                  this.percent= 100;
                  this.percentCompleted = 100;
                  this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
                  this.btsdownload = false;
                  let headers = response.headers;
                  let blob = new Blob([response.data], { type: headers["content-type"] });
                  let link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  // link.download = this.OutboxFile + "_" + this.datetime;
                  link.download = format(new Date(), "dd_MM_yyyy") + '_Inbox_CSV';
                  link.click();
                  setTimeout(() => {
                    this.opensnackbarmultidownload = false;
                    // this.snackbardowloadonetotal = false;
                  }, 2500);
                }
              }
           this.typedownloads = false;
           this.typeitem = false;

        });
      });
    },
  

    exportfileexcel() {
      let _type = parseInt(this.$route.query.type);
      console.log("data", this.$route.query);
      if (_type === 2) {
        if (this.dataAccountActive["type"] === "Citizen") {
          if (this.$route.query.biz === undefined || this.$route.query.biz === null || this.$route.query.biz === "") {
          } else {
            this.callexportfileexcel(this.$route.query.biz, "2");
          }
        } else {
          if (this.$route.query.biz === undefined || this.$route.query.biz === null || this.$route.query.biz === "") {
            this.callexportfileexcel(this.dataAccountActive.business_info["business_id"], "2");
          } else {
            this.callexportfileexcel(this.$route.query.biz, "2");
          }
        }
      } else {
        this.callexportfileexcel(this.dataAccountId, "1");
      }
    },
    // async callexportfileexcel_new(businessid, _type) {
    //   this.percentCompleted = 0;
    //   let payload = {
    //     receiver_id: [businessid],
    //     receiver_type: _type,
    //     filename: this.filename,
    //     fileextension: this.fileextension,
    //     doc_no_start: this.documentid,
    //     doc_no_end: this.documentidto,
    //     doc_date_start: this.formatDate(this.fromdatedocument),
    //     doc_date_end: this.formatDate(this.todatedocument),
    //     doc_type: this.documenttype,
    //     cre_dtm_start: this.formatDate(this.fromdateupload),
    //     cre_dtm_end: this.formatDate(this.todateupload),
    //     system: this.$route.query.systemid,
    //     sender_name: this.sendername,
    //     sellerTaxid: this.sellertaxid,
    //     sellerBranchid: this.sellerbranchid,
    //     receiver_name: this.receivername,
    //     receiverTaxid: this.buyertaxid,
    //     receiverBranchid: this.buyerbranchid,
    //     status: this.sortStatus,
    //     download: "excel",
    //     index: this.listheader,
    //   };
    //   console.log("payloadinbox", payload);
    //   this.snackbardowloadonetotalexcel = true;
    //   this.timeout = -1;
    //   let auth = await gbfGenerate.generateToken();
    //   let config = {
    //     headers: { Authorization: auth.code },
    //     onDownloadProgress: (progressEvent) => {
    //       let progresspercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //       // console.log(progresspercent);
    //       if (progresspercent === 95) {
    //         this.percentCompleted = 95;
    //       } else {
    //         this.percentCompleted = progresspercent;
    //       }
    //     },
    //     withCredentials: false,
    //     responseType: "arraybuffer",
    //   };
    //   await this.axios
    //     .post(process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/download_excel_file_inbox_byfilter_costom_index", payload, config)
    //     .then((response) => {
    //       if (response.statusText === "OK") {
    //         if (
    //           parseInt(response.headers["content-length"]) < 700 &&
    //           parseInt(response.headers["content-type"]) === "application/json"
    //         ) {
    //           console.log("response < 1000", response);
    //           this.snackbardowloadonetotalexcel = false;
    //           let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
    //           console.log("responseerror", res_error);
    //           Toast.fire({
    //             icon: "error",
    //             title: res_error.errorMessage,
    //           });
    //         } else {
    //           this.percentCompleted = 100;
    //           let headers = response.headers;
    //           let blob = new Blob([response.data], { type: headers["content-type"] });
    //           let link = document.createElement("a");
    //           link.href = window.URL.createObjectURL(blob);
    //           link.download = this.ExportInboxFilesexcel;
    //           link.click();
    //           // const blob = new Blob([response.data]);
    //           // const content = response.headers["content-type"];
    //           // saveAs(blob, this.ExportInboxFilesexcel + ".zip");
    //           setTimeout(() => {
    //             this.snackbardowloadonetotalexcel = false;
    //           }, 1000);
    //         }
    //       } else {
    //         this.snackbardowloadonetotalexcel = false;
    //       }
    //     })
    //     .catch((error) => {
    //       Toast.fire({
    //         icon: "error",
    //         title: "ไม่สามารถดาวน์โหลด " + this.ExportInboxFilesexcel + " นี้ได้",
    //       });
    //       console.log(error);
    //     });
    // },
    //อันเก่าก่อนแก้เป็น 3  step
    // async callexportfileexcel(businessid, _type) {
    //    this.percentCompleted = 0;
    //     let payload = {
    //       account_id: this.dataAccountId,
    //       receiver_id: [businessid],
    //       receiver_type: _type,
    //       filename: this.filename,
    //       fileextension: this.fileextension,
    //       doc_no_start: this.documentid,
    //       doc_no_end: this.documentidto,
    //       doc_date_start: this.formatDate(this.fromdatedocument),
    //       doc_date_end: this.formatDate(this.todatedocument),
    //       doc_type: this.documenttype,
    //       cre_dtm_start: this.formatDate(this.fromdateupload),
    //       cre_dtm_end: this.formatDate(this.todateupload),
    //       system: this.$route.query.systemid,
    //       sender_name: this.sendername,
    //       sellerTaxid: this.sellertaxid,
    //       sellerBranchid: this.sellerbranchid,
    //       receiver_name: this.receivername,
    //       receiverTaxid: this.buyertaxid,
    //       receiverBranchid: this.buyerbranchid,
    //       status:this.sortStatus
    //     };
    //     // this.snackbardowloadonetotal = true;
    //     console.log("payloadinbox", payload);
    //     this.snackbardowloadonetotalexcel = true;
    //     this.timeout = -1;
    //     let auth = await gbfGenerate.generateToken();
    //     let config = {
    //       headers: { Authorization: auth.code},
    //       onDownloadProgress: (progressEvent) => {
    //             let progresspercent = Math.round(
    //               (progressEvent.loaded * 100) / progressEvent.total
    //             );
    //             // console.log(progresspercent);
    //             if (progresspercent === 95) {
    //               this.percentCompleted = 95;
    //             } else {
    //               this.percentCompleted = progresspercent;
    //             }
    //           },
    //           withCredentials: false,
    //           responseType: "arraybuffer",
    //     }
    //     await this.axios
    //       .post(
    //         process.env.VUE_APP_SERVICE_DOWNLOAD_FILE +"/api/download_excel_file_inbox_byfilter",
    //         payload,config
    //       )
    //       .then((response) => {
    //         if (response.statusText === "OK") {
    //           if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
    //               console.log("response < 1000", response);
    //               this.snackbardowloadonetotalexcel = false;
    //               let res_error = gbfdecodearraybuffer.decodearraybuffer(
    //                 response.data
    //               );
    //               console.log("responseerror", res_error);
    //               Toast.fire({
    //                 icon: "error",
    //                 title: res_error.errorMessage,
    //               });
    //             } else {
    //               this.percentCompleted = 100;
    //               let headers = response.headers;
    //               let blob = new Blob([response.data],{type:headers['content-type']});
    //               let link = document.createElement('a');
    //               link.href = window.URL.createObjectURL(blob);
    //               link.download = this.ExportInboxFilesexcel;
    //               link.click();
    //               // const blob = new Blob([response.data]);
    //               // const content = response.headers["content-type"];
    //               // saveAs(blob, this.ExportInboxFilesexcel + ".zip");
    //               setTimeout(() => {
    //                   this.snackbardowloadonetotalexcel = false;
    //               }, 1000);
    //             }
    //         }else{
    //           this.snackbardowloadonetotalexcel = false;
    //         }
    //       })
    //       .catch((error) => {
    //         Toast.fire({
    //           icon: "error",
    //           title:
    //             "ไม่สามารถดาวน์โหลด " + this.ExportInboxFilesexcel + " นี้ได้",
    //         });
    //         console.log(error);
    //       });
    // },
     async callexportfileexcel(businessid, _type) {
     this.percentCompleted = 0;
     this.count_loadingfile = 0;
     this.statusloading = "กำลังเตรียมการดาวน์โหลด กรุณารอสักครู่...";
       let payload = {
          account_id: this.dataAccountId,
          receiver_id: [businessid],
          receiver_type: _type,
          filename: this.filename,
          fileextension: this.fileextension,
          doc_no_start: this.documentid,
          doc_no_end: this.documentidto,
          doc_date_start: this.formatDate(this.fromdatedocument),
          doc_date_end: this.formatDate(this.todatedocument),
          doc_type: this.documenttype,
          cre_dtm_start: this.formatDate(this.fromdateupload),
          cre_dtm_end: this.formatDate(this.todateupload),
          system: this.$route.query.systemid,
          sender_name: this.sendername,
          sellerTaxid: this.sellertaxid,
          sellerBranchid: this.sellerbranchid,
          receiver_name: this.receivername,
          receiverTaxid: this.buyertaxid,
          receiverBranchid: this.buyerbranchid,
          status:this.sortStatus,
          type_downloads : this.typedownloads == true ? 'Y' : 'N',
          type_document : this.typeitem == true ? 'Y' : 'N',
          sort_by: this.sort_by,
          direction: this.direction,
          refund_status: this.statusetax == "Y" ? "Y" : this.statusetax == "N" ? "N" : "",

      };
      // this.snackbardowloadonetotal = true;
      this.opensnackbarmultidownload = true;
      this.timeout = -1;
      let auth = await gbfGenerate.generateToken();
      let config = {
        headers: { Authorization: auth.code},
       
        }
      await this.axios
        .post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/prepare/download_excel_file_inbox_byfilter", payload, config)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response", response);
            this.fn_loop_multidownloading_excel(response.data.all_file, response.data.zip_name, response.data.url);  
            this.count_allfile = response.data.total;
          } else {
            // this.snackbardowloadonetotal = false;
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        })
        .catch((error) => {
          this.opensnackbarmultidownload = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถดาวน์โหลด " + this.ExportOutboxFiles + " นี้ได้",
          });
          console.log(error);
        });
    },
    async fn_loop_multidownloading_excel(all_file,zip_name,url){
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url",url);

       let total = 0;

      if (all_file.length === 0) {
        this.percent = 99;
        // console.log(url);
        // window.location.assign(url);
        // this.opensnackbarmultidownload = false;
        // this.opensnackbarmultidownload = false;
      } else {
        for (let i = 0; i < all_file.length; i++) {
          this.statusloading = "กำลังสร้างไฟล์ Excel";
          console.log("all_file.length", (1 / all_file.length) * 100, total);
          this.count_loadingfile += 1;
          total += (1 / all_file.length) * 100;
          let res = await this.downloading_multi_new_excel(
            all_file[i]["file_id"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"],
            url
          );
          console.log(res);

          if (this.percent >= 99) {
            this.percent = 99;
          } else {
            this.percent = Math.round(total);
          }
          this.newpercen = this.percent;
          this.namefile = this.InboxFile + "_" + this.datetime;
          console.log("this.percent",this.percent);
          console.log(this.percentCompleted);
          // if(res.status === "OK" ){

          // }else{
          //   continue;
   
          // }
           if(res.status !== "OK" ){
             console.log("res",res);
            this.snackbar_download_error = true;
            this.filename_error = res.filename
            this.errorMessage = res.errorMessage
            this.snackbars.push({
              filename:this.filename_error ,
              error:this.errorMessage
            })
            // continue;
          }
          console.log(i, all_file.length - 1);
          if (i === all_file.length - 1) {
            this.percent = 99;
            console.log(url);
            // window.location.assign(url);
            this.download_finish_excel(
              all_file[i]["file_id"],
              all_file[i]["location_file"],
              zip_name,
              all_file[i]["path"],
              url
            );
            // this.opensnackbarmultidownload = false;
          }
        }
      }
    },
    downloading_multi_new_excel(file_id, location_file, zip_name, all_file, url){
      let url_ =
        url + "&file_id=" +
        file_id +
        "&location_file=" +
        location_file +
        "&zip_name=" +
        zip_name +
        "&path=" +
        all_file;

         console.log(url);
      return new Promise((resolve, reject) => {
        this.axios.get(url_, {}).then((response) => {
          console.log("testtt", response.data.status);
          // if(response.data.status !== "OK"){
          //   console.log();
          // }
          setTimeout(() => {
            resolve({
              status: response.data.status,
              msg: "",
              errorMessage:response.data.errorMessage,
              filename:response.data.filename
            });
          }, 1);
        });
      });
    },
    download_finish_excel(file_id,location_file,zip_name,path,url){
      let zip = url.split('zip_id=');
      let zipid = zip[1]
      console.log("url",zip);
      let payload = {
        account_id:this.dataAccountId,
        zip_id: zipid,
        name:this.namefile,
        path:path,
        type_document : this.typeitem == true ? 'Y' : 'N'
      }
      console.log("payload",payload);
      return new Promise((resolve, reject) => {
        this.axios.post(process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/download_file_excel_finish", payload,{
          withCredentials: false,
          responseType: "arraybuffer",
        })
        .then((response) => {
           this.statusloading = "กำลังรวมไฟล์";
          // console.log("testtt", response.data.status);
           if (response.statusText === "OK") {
                console.log("response++++++++++++ OK", response);
                // ตรวจสอบว่า Backend ส่ง Error มาหรือไม่
                if (
                  parseInt(response.headers["content-length"]) < 700 &&
                  parseInt(response.headers["content-type"]) === "application/json"
                ) {
                  console.log("response < 1000", response);
                  this.opensnackbarmultidownload = false;
                  // this.snackbardowloadonetotal = false;
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                  console.log("responseerror", res_error);
                  Toast.fire({
                    icon: "error",
                    title: res_error.errorMessage,
                  });
                } else {
                  this.percent= 100;
                  this.percentCompleted = 100;
                  this.statusloading = "ดาวน์โหลดไฟล์สำเร็จ";
                  this.btsdownload = false;
                  let headers = response.headers;
                  let blob = new Blob([response.data], { type: headers["content-type"] });
                  let link = document.createElement("a");
                  link.href = window.URL.createObjectURL(blob);
                  // link.download = this.OutboxFile + "_" + this.datetime;
                  link.download = format(new Date(), "dd_MM_yyyy") + '_Inbox_Excel';
                  link.click();
                  setTimeout(() => {
                    this.opensnackbarmultidownload = false;
                    // this.snackbardowloadonetotal = false;
                  }, 2500);
                }
              }
              this.typedownloads = false;
              this.typeitem = false;
        });
      });
    },


    fn_setheader(type) {
      console.log(type);
      this.typedownload = type;
      this.opendialogselectheader = true;
    },
    checkdownloadcsvandexcel(item) {
      this.listheader = [];
      console.log("item", item);
      for (let i = 0; i < item.length; i++) {
        if (item[i].select !== null) {
          this.listheader.push(item[i].header_name);
        }
      }
      console.log("listheader", this.listheader);
      if (this.typedownload === "csv") {
        this.exportfilecsv();
      } else {
        this.exportfileexcel();
      }
    },
    convertstorageToByte(_storage) {
      console.log(_storage);
      let splitStorage = _storage.split(" ");
      let databyte = 0;

      if (splitStorage[1] === "KB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1024 : parseFloat(splitStorage[0]) * 1024;
      } else if (splitStorage[1] === "MB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1048576 : parseFloat(splitStorage[0]) * 1048576;
      } else if (splitStorage[1] === "GB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1073741824 : parseFloat(splitStorage[0]) * 1073741824;
      } else if (splitStorage[1] === "TB") {
        databyte =
          typeof splitStorage[0] === "number" ? splitStorage[0] * 1099511627776 : parseFloat(splitStorage[0]) * 1099511627776;
      } else {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] : parseFloat(splitStorage[0]);
      }
      return Math.ceil(databyte);
    },
    async getfeature() {
      var payload = {
        account_id : this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id
      }
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_default_inbox", payload, {
          headers: { Authorization: auth.code },
        })
        .then((response) => {
          if (response.data.status === "OK") {
            console.log('response', response);
            this.feature = response.data.default_inbox;
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            })
          }
        }).catch(error => {
          console.log(error);
          Toast.fire({
            icon: "error",
            title: this.$t("toast.cannotconnectonebox")
          })
        })
    },
  },
  created() {
    this.fromdateupload = this.$route.query.fromdate || "";
    this.todateupload = this.$route.query.todate || "";
    this.filename = this.$route.query.filename || "";
    this.fileextension = this.$route.query.fileextension || "";
    this.fromdateupload = this.$route.query.fromdate || "";
    this.todateupload = this.$route.query.todate || "";
    this.fromdatedocument = this.$route.query.fromdatedoc || "";
    this.todatedocument = this.$route.query.todatedoc || "";
    this.documentid = this.$route.query.doc_no_start || "";
    this.documentidto = this.$route.query.doc_no_end || "";
    this.sendername = this.$route.query.sender_name || "";
    this.sellertaxid = this.$route.query.sellerTaxid || "";
    this.sellerbranchid = this.$route.query.sellerBranchid || "";
    this.receivername = this.$route.query.receiver_name || "";
    this.buyertaxid = this.$route.query.receiverTaxid || "";
    this.buyerbranchid = this.$route.query.receiverBranchid || "";
  },
  mounted() {
    this.getSourceSystem();
    this.getDataFilters();
    this.getfeature();
    console.log("v-if", this.status_permission_inbox);
    //   console.log(this.$router.app['name'])
  },
};
</script>

<style>
.v-snack__content {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
}
#thiscontainer_inboxsourcesystem {
  /* height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch; */
  height: calc(101vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
.pointer {
  cursor: pointer;
}

#btn {
  text-transform: none;
}
.select {
  max-height: 20px;
}
</style>
